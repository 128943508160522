import {GroupWithAllParameters} from "../../api/models/GroupWithAllParameters";
import "../../styles/group/groupSettings.css"

const GroupSettings = (group: GroupWithAllParameters) => {

    return (
        <div className={"groupSettings"}>
            <div className={"singleParameter parameterRegions"}>
                <div className={"parameterHeading"}>Регионы показов:</div>
                <div className={"parameterValue"}>{group.regions}</div>
            </div>
            {
                group.keyPhrases !== "" ?
                <div className={"singleParameter parameterKeyPhrases"}>
                    <div className={"parameterHeading"}>Ключевые фразы:</div>
                    <div className={"parameterValue"}>{group.keyPhrases}</div>
                </div>:
                <></>
            }
            {
                group.interests !== "" ?
                    <div className={"singleParameter parameterInterests"}>
                        <div className={"parameterHeading"}>Интересы:</div>
                        <div className={"parameterValue"}>{group.interests}</div>
                    </div>:
                    <></>
            }
            {
                group.ageFrom !== "" && group.ageTo !== "" ?
                    <div className={"singleParameter parameterAge"}>
                        <div className={"parameterHeading"}>Возраст:</div>
                        <div className={"parameterValue"}>{group.ageFrom}-{group.ageTo}</div>
                    </div>:
                    <></>
            }
            {
                group.age !== "" ?
                    <div className={"singleParameter parameterAge"}>
                        <div className={"parameterHeading"}>Возраст:</div>
                        <div className={"parameterValue"}>{group.age}</div>
                    </div>:
                    <></>
            }
            {
                group.sex ?
                    <div className={"singleParameter parameterSex"}>
                        <div className={"parameterHeading"}>Пол:</div>
                        <div className={"parameterValue"}>
                            {group.sex.map(sex => sex === "Male" ? "М" : "Ж").join(", ")}
                        </div>
                    </div>:
                    <></>
            }

        </div>
    )
}

export default GroupSettings
