import {AdsWithAllParameters} from "../../api";


const SingleSearchAds = ({ads}: {ads: AdsWithAllParameters}) => {

    return (
        <div className={"singleSearchAds"} data-id={ads.id} data-type={ads.type}>
            <div className={"singleAdsElement"}>
                <span>Заголовок 1: </span>
                {ads.heading1}
            </div>
            <div className={"singleAdsElement"}>
                <span>Заголовок 2: </span>
                {ads.heading2}
            </div>
            <div className={"singleAdsElement"}>
                <span>Текст: </span>
                {ads.text}
            </div>
            <div className={"singleAdsElement"}>
                <span>Ссылка: </span>
                <a href={ads.link && !ads.link.startsWith("http") ? `http://${ads.link}` : ads.link || "#"} target="_blank">{ads.link}</a>
            </div>
        </div>
    )
}

export default SingleSearchAds
