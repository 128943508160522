import UserList from "../components/UserList";
import AccountsList from "../components/AccountsList";
import "../styles/mainStyles.css"
import "../styles/mainPage.css"
import CampaignsList from "../components/CampaignsList";
import Header from "../components/header/Header";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {UserService, UserType} from "../api";
import Data from "../stores/Data"
import {observer} from "mobx-react";
import {Helmet} from "react-helmet-async";
import GroupsList from "../components/groups/GroupsList";

const MainPage = observer(() => {
    const navigation = useNavigate()


    useEffect( () => {
        if (localStorage.getItem("accessToken") == null) {
            navigation('/login')
        } else {
            // узнаем тип пользователя, чтобы знать, надо ли выводить админские данные
            const getMyUser = async () => {
                try{
                    await UserService.getApiUsersGetMyUser().then(resp => {
                        Data.setUserType(resp.type)
                        Data.setUserId(resp.id)
                    }).catch(err => {
                        // console.log("myUser error " + err)
                        // console.log("accessToken " + localStorage.getItem("accessToken"))
                    })
                } catch (err) {

                }
            }
            getMyUser()
        }
    },[])



    return (
        <>
            <Helmet>
                <title>Личный кабинет | UnyMark</title>
            </Helmet>
            <Header/>
            <div className={`mainPage pageWrapper ${Data.userType === UserType.ADMIN ? 'adminPage' : 'userPage'}`}>
                <div className="container">
                    {
                        Data.userType === UserType.ADMIN ? <UserList/> : <></>
                    }
                    <AccountsList/>
                    <CampaignsList/>
                    <GroupsList/>
                </div>
            </div>
        </>

    )
})

export default MainPage
