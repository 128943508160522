import {usePopupWithContent} from "../PopupWithContent";
import {AdsAndLogoImage} from "../../api";
import Data from "../../stores/Data";
import AdvImagesStore from "../../stores/AdvImagesStore";
import {useEffect, useState} from "react";


const AdvImage: React.FC<{ data: AdsAndLogoImage }> = ({ data }) => {
    const { showMessageWithContent } = usePopupWithContent()
    const [imagePath, setImagePath] = useState<string>("");

    useEffect(() => {
        const fetchImagePath = async () => {
            try {
                const link = await AdvImagesStore.getImageFileById(data.id);
                setImagePath(link);
            } catch (error) {
                console.error("Error fetching image: ", error)
            }
        }
        fetchImagePath()
    }, [data.id])




    const imageClickHandler = () => {
        showMessageWithContent(
            " ",
            <div className={"AdvImageContainer"}>
                <img src={imagePath} alt={data.name?.toString()}/>
            </div>
            )
    }
    return (
        <div className={"AdvImage"} id={data.id} onClick={imageClickHandler}>
            <div className={"AdvImageContainer"}>
                <img src={imagePath} alt={data.name?.toString()}/>
            </div>
        </div>
    );
}

export default AdvImage
