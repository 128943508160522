import {makeAutoObservable, runInAction} from "mobx";
import {ImageService, AdsAndLogoImage} from "../api";

class AdvImagesStore {
    advImages: Array<AdsAndLogoImage> = []

    constructor() {
        makeAutoObservable(this);
    }

    async getImagesByCampaignFromServer(campaignId: string): Promise<AdsAndLogoImage[]> {
        try {
            const images = await ImageService.getApiImagesGetImagesListByCampaign(campaignId);
            runInAction(() => {
                this.advImages = images;
            });
            return images;
        } catch (error) {
            console.error("Error: " + error);
            runInAction(() => {
                this.advImages = [];
            });
            throw error;
        }
    }

    async getImageFileById(imageId: string): Promise<string> {
        try {
            const imageLink = await ImageService.getApiImagesGetImage(imageId);
            return imageLink;
        } catch (error) {
            throw error;
        }
    }
}

export default new AdvImagesStore()
