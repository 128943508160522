import React, { createContext, useContext, ReactNode, useState } from 'react';
import "../styles/popup.css";
import IconClose from "../images/icons/close.svg";


interface PopupContextProps {
    showMessage: (heading: string, message: string) => void;
    closeMessage: () => void;
}

const PopupContext = createContext<PopupContextProps | undefined>(undefined);

interface PopupProviderProps {
    children: ReactNode;
}

export const PopupProvider: React.FC<PopupProviderProps> = ({ children }) => {
    const [heading, setHeading] = useState<string | null>(null);
    const [message, setMessage] = useState<string | null>(null);

    const showMessage = (newHeading: string, newMessage: string) => {
        setHeading(newHeading);
        setMessage(newMessage);
    };
    const closeMessage = () => {
        setHeading(null);
        setMessage(null);
    }

    return (
        <PopupContext.Provider value={{ showMessage, closeMessage }}>
            {children}
            {heading && message && <PopupMessage message={message} heading={heading}/>}
        </PopupContext.Provider>
    );
};

export const usePopup = () => {
    const context = useContext(PopupContext);

    if (!context) {
        throw new Error('usePopup must be used within a PopupProvider');
    }

    return context;
};

const PopupMessage: React.FC<{ message: string, heading: string }> = ({ heading,message }) => {
    const {closeMessage} = usePopup();
    return (
        <div className={"popup"}>
            <div className={"popupBackground"}></div>
            <div className={"popupContent"}>
                <div className={"popupContainer"}>
                    <div className={"popupHeader"}>
                        <div className={"popupHeading"}>{heading}</div>
                        <div onClick={closeMessage} className={"popupClose"}>
                            <img className={""} src={IconClose}/>
                        </div>
                    </div>
                    <div className={"popupMessag"}>{message}</div>
                </div>
            </div>
        </div>
    )
};
