import {AdsWithAllParameters} from "../../api";
import AdvVideo from "../advTypes/AdvVideo";
import AdvImage from "../advTypes/AdvImage";

const SingleMediaAds = ({ads}: {ads: AdsWithAllParameters}) => {
    console.log("получил ads")
    console.log(ads)
    return (
        <div className={"singleMediaAds"} data-id={ads.id}>
            <div className={"singleAdsElement"}>
                <span>Ссылка: </span>
                <a href={ads.link && !ads.link.startsWith("http") ? `http://${ads.link}` : ads.link || "#"} target="_blank">{ads.link}</a>
            </div>
            {
                ads.image ?
                    <div className={"singleAdsElement imageElement"}>
                        <span>Картинка: </span>
                        {/*<img src={imagePath}/>*/}
                        <AdvImage data={ads.image}/>
                    </div> :
                    <></>
            }
            {
                ads.video ?
                    <div className={"singleAdsElement videoElement"}>
                        <span>Видео: </span>
                        <AdvVideo data={ads.video}/>
                    </div> :
                    <></>
            }
        </div>
    )
}

export default SingleMediaAds
