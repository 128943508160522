/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CampaignWithId } from '../models/CampaignWithId';
import type { CreatableCampaign } from '../models/CreatableCampaign';
import type { EditableCampaign } from '../models/EditableCampaign';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CampaignsService {

    /**
     * @param account 
     * @param requestBody 
     * @returns CreatableCampaign Success
     * @throws ApiError
     */
    public static postApiCampaignCreateCampaign(
account?: string,
requestBody?: CreatableCampaign,
): CancelablePromise<CreatableCampaign> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/campaign/createCampaign',
            query: {
                'account': account,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns CreatableCampaign Success
     * @throws ApiError
     */
    public static getApiCampaignGetCampaign(
id?: string,
): CancelablePromise<CreatableCampaign> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/campaign/getCampaign',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param account 
     * @returns CampaignWithId Success
     * @throws ApiError
     */
    public static getApiCampaignGetCampaignsByAccount(
account?: string,
): CancelablePromise<Array<CampaignWithId>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/campaign/getCampaignsByAccount',
            query: {
                'account': account,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns EditableCampaign Success
     * @throws ApiError
     */
    public static patchApiCampaignUpdateCampaign(
id?: string,
requestBody?: EditableCampaign,
): CancelablePromise<EditableCampaign> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/campaign/updateCampaign',
            query: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiCampaignDeleteCampaign(
id?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/campaign/deleteCampaign',
            query: {
                'id': id,
            },
        });
    }

}
