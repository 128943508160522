import Data from "../stores/Data";
import {CampaignWithId} from "../api";
import {useNavigate} from "react-router-dom";
import "../styles/singleCampaignInList.css"
import {useEffect, useState} from "react";
import {observer} from "mobx-react";

interface ICampaingProps {
    campaign: CampaignWithId
}

const SingleCampaignInList =  observer(({campaign}: ICampaingProps) => {
    const navigation = useNavigate();
    const [activeClass, setActiveClass] = useState("")

    const ShowStatisticsButtonHandler = () => {
        Data.setCampaignId(campaign.id);
        Data.setCampaignType(campaign.type);
        Data.setCampaignName(campaign.name ?? "")
        navigation("/statistics");
    }

    const SingleCampaignClickHandler = () => {
        Data.setCampaignId(campaign.id);
        Data.setCampaignType(campaign.type);
        Data.setCampaignName(campaign.name ?? "")
    }

    useEffect(() => {
        if (campaign.id === Data.campaignId) {
            setActiveClass("active")
        } else {
            setActiveClass("")
        }
    }, [Data.campaignId, campaign.id])

    return (
        <div className={"singleCampaignInList listElement " + activeClass} data-campaign={campaign.id} onClick={SingleCampaignClickHandler}>
            <div className="campaignListName"><strong>Кампания:</strong> {campaign.name}</div>
            <div className="campaignListType"><strong>Тип кампании:</strong> {campaign.type}</div>
            <button
                className={"showStatisticsButton hoverStyleButton"}
                onClick={ShowStatisticsButtonHandler}
            >Перейти к статистике</button>
        </div>
    )
})

export default SingleCampaignInList
