/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum StatisticsType {
    DIRECT_SEARCH_AND_BANNERS = 'DirectSearchAndBanners',
    DIRECT_MEDIA = 'DirectMedia',
    VK = 'VK',
}
