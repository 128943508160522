/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AdsType {
    YANDEX_SEARCH = 'YandexSearch',
    YANDEX_MEDIA = 'YandexMedia',
    VK = 'VK',
}
