import {AdsWithAllParameters, AdvertisementsService, AggregateStatistic, StatisticsType} from "../../api";
import {observer} from "mobx-react";
import SingleSearchAds from "../ads/SingleSearchAds";
import {useEffect, useState} from "react";
import AdsStore from "../../stores/AdsStore";
import SingleMediaAds from "../ads/SingleMediaAds";
import SingleVkAds from "../ads/SingleVkAds";

interface IStatisticsProps {
    statisticsElement: AggregateStatistic
}

const SingleStatElementSearch = observer(({statisticsElement}: IStatisticsProps) => {

    const [currentAds, setCurrentAds] = useState<AdsWithAllParameters>()

    const GetAdsWithAllParams = async () => {
        const response = await AdvertisementsService.getApiAdsGetAdsById(statisticsElement.id);
        setCurrentAds(response)
    }

    useEffect(() => {
        GetAdsWithAllParams()
    }, [statisticsElement.id])



    let Row;
    switch (statisticsElement.type){
        case StatisticsType.DIRECT_SEARCH_AND_BANNERS:
            Row = () => {
                return <div className={"singleStatisticsInTable DirectSearchAndBanners"} data-id={statisticsElement.id}>
                    <div className={"statsField"}>
                        {currentAds && <SingleSearchAds ads={currentAds}/>}
                    </div>
                    <div className={"statsField"}>{statisticsElement.views}</div>
                    <div className={"statsField"}>{statisticsElement.clicks}</div>
                    <div className={"statsField"}>{statisticsElement.ctr}</div>
                    <div className={"statsField"}>{statisticsElement.cpc}</div>
                    <div className={"statsField"}>{statisticsElement.fullCost}</div>
                    <div className={"statsField"}>{statisticsElement.avgBid}</div>
                    <div className={"statsField"}>{statisticsElement.avgPosOfView ?? 0}</div>
                    <div className={"statsField"}>{statisticsElement.avgPosOfClick ?? 0}</div>
                    <div className={"statsField"}>{statisticsElement.avgTrafficVolume ?? 0}</div>
                </div>
            }
            break;
        case StatisticsType.DIRECT_MEDIA:
            Row = () => {
                return <div className={"singleStatisticsInTable DirectMedia"}>
                    <div className={"statsField"}>
                        {currentAds && <SingleMediaAds ads={currentAds} />}
                    </div>
                    <div className={"statsField"}>{statisticsElement.views}</div>
                    <div className={"statsField"}>{statisticsElement.clicks}</div>
                    <div className={"statsField"}>{statisticsElement.ctr}</div>
                    <div className={"statsField"}>{statisticsElement.cpc}</div>
                    <div className={"statsField"}>{statisticsElement.fullCost}</div>
                    <div className={"statsField"}>{statisticsElement.costPerThousandViews ?? 0}</div>
                </div>
            }
            break;
        case StatisticsType.VK:
            Row = () => {
                return <div className={"singleStatisticsInTable VK"}>
                    <div className={"statsField"}>
                        {currentAds && <SingleVkAds ads={currentAds} />}
                    </div>
                    <div className={"statsField"}>{statisticsElement.views}</div>
                    <div className={"statsField"}>{statisticsElement.clicks}</div>
                    <div className={"statsField"}>{statisticsElement.ctr}</div>
                    <div className={"statsField"}>{statisticsElement.cpc}</div>
                    <div className={"statsField"}>{statisticsElement.fullCost}</div>
                    <div className={"statsField"}>{statisticsElement.costPerThousandViews ?? 0}</div>
                </div>
            }
            break;
        default:
            Row = () => null;
            break;
    }
    return (
        <Row/>
    )
})

export default SingleStatElementSearch
