import React from 'react';
import './App.css';
import MainPage from "./pages/MainPage";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Statistics from "./pages/Statistics";
import {PopupProvider} from "./components/PopupMessage";
import {PopupWithContentProvider} from "./components/PopupWithContent";
import LoginPage from "./pages/LoginPage";
import LoginAdminPage from "./pages/LoginAdminPage";
import {HelmetProvider} from "react-helmet-async";

function App() {
  return (
      <div className={"fullPageBody"}>
          <BrowserRouter>
              <HelmetProvider>
                  <PopupProvider>
                      <PopupWithContentProvider>
                          <Routes>
                              <Route path={'/'} element={<MainPage/>}/>
                              <Route path={'/statistics'} element={<Statistics/>}/>
                              <Route path={'/login'} element={<LoginPage/>}/>
                              <Route path={'/admin'} element={<LoginAdminPage/>}/>
                          </Routes>
                      </PopupWithContentProvider>
                  </PopupProvider>
              </HelmetProvider>
          </BrowserRouter>
      </div>

  );
}

export default App;
