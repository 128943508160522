import React, {useEffect, useRef, useState} from "react";
import Select, {ActionMeta} from "react-select";
import {User, UserService} from "../api";
import ErrorMsg from "./ErrorMsg";
import {observer} from "mobx-react";
import Data from "../stores/Data";
import "../styles/userList.css"

interface SingleOption{
    value: string | undefined;
    label: string | undefined | null;
}

const UserList = observer(() => {
    const [options, setOptions] = useState<Array<SingleOption>>([])
    const [error, setError] = useState<String|unknown>("");
    const [currentUser, setCurrentUser] = useState("");

    useEffect(() => {
        // Пример вызова метода getApiUsersGetUsers
        const getAllUsers = async () => {
            try {
                const response = await UserService.getApiUsersGetUsers();
                //console.log(response); // Обрабатывайте полученный ответ сервера
                //setUsers(response);
                setOptions([]);
                response.map((item) => {
                    let singleElem: SingleOption = {value: item.id, label: item.name}
                    setOptions(current => [...current, singleElem]);
                })
            } catch (error) {
                console.error(error); // Обрабатывайте ошибки при вызове метода
                setError(error);
            }
        };

        // Вызов метода получения списка всех пользователей
        getAllUsers();
    }, []);


    const getUserValue = () => {
        return currentUser ? options.find(u => u.value === currentUser) : ""
    }

    const userListOnChange = (user:any) => {
        setCurrentUser(user.value)
        Data.setUserId(user.value);
    }

    return (
        <div className="userlist singleColumnOnMainPage">
                <div className={"heading"}>Выберите пользователя из списка</div>
                <Select
                    className="userListSelect"
                    isSearchable={true}
                    options={options}
                    name="usersList"
                    placeholder="Выберите пользователя"
                    onChange = {userListOnChange}
                />
        </div>
    );
})

export default UserList
