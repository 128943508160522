import {makeAutoObservable} from "mobx";
import {AccountType, CampaignType, GroupType, UserType} from "../api";
import GroupsStore from "./GroupsStore";

class Data {
    userId = "";
    accountId = "";
    campaignId = "";
    campaignName = "";
    userType = "";
    campaignType?:CampaignType = undefined;
    accountType?:AccountType = undefined;
    groupId = "";
    groupType?:GroupType = undefined;
    groupName = "";
    dateFrom = new Date();
    dateTo = new Date();


    constructor() {
        makeAutoObservable(this)
    }

    setUserId (value: string)
    {
        this.userId = value;
        this.accountId = "";
        this.campaignId = "";
        this.groupId = "";
    }

    setUserType (value: UserType)
    {
        this.userType = value
    }

    clearUserType ()
    {
        this.userType = ""
    }

    setAccountId (value: string )
    {
        this.accountId = value;
        this.campaignId = "";
        this.groupId = "";
        GroupsStore.clearGroupsList();
    }

    setCampaignId (value: string)
    {
        this.campaignId = value;
        this.groupId = "";
    }

    setCampaignType (value: CampaignType)
    {
        this.campaignType = value;
    }
    setCampaignName (value: string)
    {
        this.campaignName = value;
    }

    setAccountType (value: AccountType)
    {
        this.accountType = value
    }

    setGroupId (value: string)
    {
        this.groupId = value;
    }

    setGroupType (value: GroupType)
    {
        this.groupType = value;
    }

    setGroupName (value: string)
    {
        this.groupName = value;
    }

    setDateFrom (value: Date)
    {
        this.dateFrom = value;
    }
    setDateTo (value: Date)
    {
        this.dateTo = value;
    }
}

export default new Data()
