import {AggregateStatistic, StatisticsType, StatisticsWithAllParameters} from "../../api";
import Data from "../../stores/Data"
import {observer} from "mobx-react";
import {useEffect} from "react";
interface IStatisticsProps {
    statisticsElement: AggregateStatistic
}

const SingleGroupStatisticInTable = observer(({statisticsElement}: IStatisticsProps) => {

    useEffect(() => {
        Data.setGroupId("")
        Data.setGroupName("")
    }, [])

    const GroupClickHandler = () => {
        console.log("click on group ", statisticsElement.id)
        Data.setGroupId(statisticsElement.id);
        Data.setGroupName(statisticsElement.name ?? "")
    }
    let Row;
    switch (statisticsElement.type){
        case StatisticsType.DIRECT_SEARCH_AND_BANNERS:
            Row = () => {
                return <div onClick={GroupClickHandler} className={"singleStatisticsInTable DirectSearchAndBanners"}
                data-id={statisticsElement.id}
                >
                    <div className={"statsField"}>{statisticsElement.name}</div>
                    <div className={"statsField"}>{statisticsElement.views}</div>
                    <div className={"statsField"}>{statisticsElement.clicks}</div>
                    <div className={"statsField"}>{statisticsElement.ctr}</div>
                    <div className={"statsField"}>{statisticsElement.cpc}</div>
                    <div className={"statsField"}>{statisticsElement.fullCost}</div>
                    <div className={"statsField"}>{statisticsElement.avgBid}</div>
                    <div className={"statsField"}>{statisticsElement.avgPosOfView ?? 0}</div>
                    <div className={"statsField"}>{statisticsElement.avgPosOfClick ?? 0}</div>
                    <div className={"statsField"}>{statisticsElement.avgTrafficVolume ?? 0}</div>
                </div>
            }
            break;
        case StatisticsType.DIRECT_MEDIA:
        case StatisticsType.VK:
            Row = () => {
                return <div onClick={GroupClickHandler} className={"singleStatisticsInTable DirectMedia"}>
                    <div className={"statsField"}>{statisticsElement.name}</div>
                    <div className={"statsField"}>{statisticsElement.views}</div>
                    <div className={"statsField"}>{statisticsElement.clicks}</div>
                    <div className={"statsField"}>{statisticsElement.ctr}</div>
                    <div className={"statsField"}>{statisticsElement.cpc}</div>
                    <div className={"statsField"}>{statisticsElement.fullCost}</div>
                    <div className={"statsField"}>{statisticsElement.costPerThousandViews ?? 0}</div>
                </div>
            }
            break;
        default:
            Row = () => null;
            break;
    }
    return (
            <Row/>
    )
})

export default SingleGroupStatisticInTable;
