import {makeAutoObservable, runInAction} from "mobx";
import {AggregateStatistic, StatisticsService, StatisticsWithAllParameters} from "../api";
import Data from "./Data";
import {StatisticsToAddToServer} from "../CustomTypes";

class StatisticsStore {
    statistics: Array<StatisticsWithAllParameters> = []
    statisticsGroup: Array<AggregateStatistic> = []
    statisticsAdsByGroup: Array<AggregateStatistic> = []
    statisticsAdsByCampaign: Array<AggregateStatistic> = []
    statisticsTotalByCampaign: StatisticsWithAllParameters | null = null;

    constructor() {
        makeAutoObservable(this)
    }

    async addStatisticsToServer(element: StatisticsToAddToServer) {
        try {
            const response = await StatisticsService.postApiStatisticsCreateStatistics(
                Data.campaignId,
                element.creatableStatisticsElement.type,
                element.costPerThousandViews ? element.costPerThousandViews : 0,
                element.avgPosOfView ? element.avgPosOfView : 0,
                element.avgPosOfClick ? element.avgPosOfClick : 0,
                element.avgTrafficVolume ? element.avgTrafficVolume : 0,
                element.creatableStatisticsElement
            )
            runInAction(() => {
                this.statistics = [...this.statistics, response as StatisticsWithAllParameters]
                // Сортировка массива по полю Date в убывающем порядке
                this.statistics.sort((a, b) => {
                    const dateA = new Date(a.date).getTime();
                    const dateB = new Date(b.date).getTime();
                    return dateA - dateB;
                });
            })
            return true;
        } catch (error) {
            console.log("error from store: " + error)
            console.log(error)
            throw error;
        }
    }

    async removeStatistics(id:string) {
        try {
            await StatisticsService.deleteApiStatisticsDeleteStatistics(id);
            this.statistics = this.statistics.filter(item => item.id !== id);
        } catch (error) {
            throw error;
        }


    }

    async getAllStatisticsFromServer(dateFrom: Date | null, dateTo: Date | null ) {
        try {
            const response = await StatisticsService.getApiStatisticsGetStatisticsByCampaign(Data.campaignId, dateFrom?.toISOString(), dateTo?.toISOString());
            runInAction(() => {
                this.statistics = Array.isArray(response) ? response : [response];
            });
            return this.statistics
        } catch (error) {
            console.error("Error: " + error); // Обрабатывайте ошибки при вызове метода
            runInAction(() => {
                this.statistics = [];
            })
            this.statistics = [];
            throw false;
        }
    }

    async getStatisticsListGroupsByCampaign(groupId: string, dateFrom: Date | null, dateTo: Date | null) {
        try {
            //const response = await StatisticsService.getApiStatisticsGetStatisticsByGroup(groupId, dateFrom?.toISOString(), dateTo?.toISOString());
            const response = await StatisticsService.getApiStatisticsGetStatisticsListFromGroupByCampaign(groupId, dateFrom?.toISOString(), dateTo?.toISOString())
            runInAction(() => {
                this.statisticsGroup = Array.isArray(response) ? response : [response];
            });
            return this.statisticsGroup
        } catch (error) {
            console.error("Error: " + error); // Обрабатывайте ошибки при вызове метода
            runInAction(() => {
                this.statisticsGroup = [];
            })
            this.statisticsGroup = [];
            throw false;
        }
    }

    async getStatisticsListAdsByGroup(groupId: string, dateFrom: Date | null, dateTo: Date | null) {
        try {
            const response = await StatisticsService.getApiStatisticsGetStatisticsListFromAdsByGroup(groupId, dateFrom?.toISOString(), dateTo?.toISOString())
            runInAction(() => {
                this.statisticsAdsByGroup = Array.isArray(response) ? response : [response];
            });
            return this.statisticsAdsByGroup
        } catch (error) {
            console.error("Error: " + error); // Обрабатывайте ошибки при вызове метода
            runInAction(() => {
                this.statisticsAdsByGroup = [];
            })
            this.statisticsAdsByGroup = [];
            throw false;
        }
    }

    async getStatisticsListAdsByCampaign(campaignId: string, dateFrom: Date | null, dateTo: Date | null) {
        try {
            const response = await StatisticsService.getApiStatisticsGetStatisticsListFromAdsByCampaign(campaignId, dateFrom?.toISOString(), dateTo?.toISOString())
            runInAction(() => {
                this.statisticsAdsByCampaign = Array.isArray(response) ? response : [response];
            });
            return this.statisticsAdsByCampaign
        } catch (error) {
            console.error("Error: " + error);
            runInAction(() => {
                this.statisticsAdsByCampaign = [];
            })
            this.statisticsAdsByCampaign = [];
            throw false;
        }
    }

    async getStatisticsTotalByCampaign(campaignId: string, dateFrom: Date | null, dateTo: Date | null)
    {
        try {
            //const response = await StatisticsService.getApiStatisticsGetStatisticsByCampaign(campaignId, dateFrom?.toISOString(), dateTo?.toISOString())
            const response = await StatisticsService.getApiStatisticsGetAggregateStatisticByCampaign(campaignId,dateFrom?.toISOString(), dateTo?.toISOString());
            runInAction(() => {
                this.statisticsTotalByCampaign = response;
            })
            return this.statisticsTotalByCampaign
        } catch (error) {
            //console.error("Error: " + error);

            runInAction(() => {
                this.statisticsTotalByCampaign = null;
            })
            this.statisticsTotalByCampaign = null;
        }
    }

    clearAllStatisticsByAds () {
        this.statisticsAdsByCampaign = [];
        this.statisticsAdsByGroup = []
    }

    clearStatisticsByGroup () {
        this.statisticsAdsByGroup = [];
    }


    // updateStatistics(element: StatisticsWithAllParameters) {
    //     this.statistics = this.statistics.map(item => element.id === element.id ? element : item)
    // }
}

export default new StatisticsStore()
