/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdsWithAllParameters } from '../models/AdsWithAllParameters';
import type { CreatableAds } from '../models/CreatableAds';
import type { EditableAds } from '../models/EditableAds';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdvertisementsService {

    /**
     * @param group 
     * @param heading1 
     * @param heading2 
     * @param text 
     * @param vkHeading 
     * @param vkShortText 
     * @param vkLongText 
     * @param requestBody 
     * @returns CreatableAds Success
     * @throws ApiError
     */
    public static postApiAdsCreateAds(
group?: string,
heading1?: string,
heading2?: string,
text?: string,
vkHeading?: string,
vkShortText?: string,
vkLongText?: string,
requestBody?: CreatableAds,
): CancelablePromise<CreatableAds> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ads/createAds',
            query: {
                'group': group,
                'heading1': heading1,
                'heading2': heading2,
                'text': text,
                'vkHeading': vkHeading,
                'vkShortText': vkShortText,
                'vkLongText': vkLongText,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns AdsWithAllParameters Success
     * @throws ApiError
     */
    public static getApiAdsGetAdsById(
id?: string,
): CancelablePromise<AdsWithAllParameters> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ads/getAdsById',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param group 
     * @returns AdsWithAllParameters Success
     * @throws ApiError
     */
    public static getApiAdsGetAdsByGroup(
group?: string,
): CancelablePromise<Array<AdsWithAllParameters>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ads/getAdsByGroup',
            query: {
                'group': group,
            },
        });
    }

    /**
     * @param id 
     * @param heading1 
     * @param heading2 
     * @param text 
     * @param imageId 
     * @param logoImageId 
     * @param vkHeading 
     * @param vkShortText 
     * @param vkLongText 
     * @param vkImages 
     * @param videoId 
     * @param requestBody 
     * @returns EditableAds Success
     * @throws ApiError
     */
    public static patchApiAdsUpdateAds(
id?: string,
heading1?: string,
heading2?: string,
text?: string,
imageId?: string,
logoImageId?: string,
vkHeading?: string,
vkShortText?: string,
vkLongText?: string,
vkImages?: Array<string>,
videoId?: string,
requestBody?: EditableAds,
): CancelablePromise<EditableAds> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/ads/updateAds',
            query: {
                'id': id,
                'heading1': heading1,
                'heading2': heading2,
                'text': text,
                'imageId': imageId,
                'logoImageId': logoImageId,
                'vkHeading': vkHeading,
                'vkShortText': vkShortText,
                'vkLongText': vkLongText,
                'vkImages': vkImages,
                'videoId': videoId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiAdsDeleteAds(
id?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/ads/deleteAds',
            query: {
                'id': id,
            },
        });
    }

}
