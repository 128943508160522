import {makeAutoObservable, runInAction} from "mobx";
import {AdsWithAllParameters, AdvertisementsService, GroupsService} from "../api";

class AdsStore {
    ads: Array<AdsWithAllParameters> = [];

    constructor() {
        makeAutoObservable(this)
    }

    async getGroupsByCampaignFromServer(groupId: string) {
        try {
            const response = await AdvertisementsService.getApiAdsGetAdsByGroup(groupId);
            runInAction(() => {
                this.ads = response;
            });

            return this.ads
        } catch (error) {
            console.error("Error: " + error);
            runInAction(() => {
                this.ads = [];
            })
            this.ads = [];
            throw false;
        }
    }

    clearAdsList() {
        this.ads = [];
    }


}

export default new AdsStore()
