import {observer} from "mobx-react";
import Data from "../stores/Data";
import React, {useEffect, useState} from "react";
import SingleCampaignInList from "./SingleCampaignInList";
import "../styles/campaignList.css"
import CampaignsStore from "../stores/CampaignsStore";
import {usePopupWithContent} from "./PopupWithContent";
import AddCampaignForm from "./AddCampaignForm";
import {UserType} from "../api";


const CampaignsList = observer(() => {
    const [heading, setHeading] = useState<string>("")
    const [emptyCampaignsList, setEmptyCampaignsList] = useState<boolean>(false);
    const {showMessageWithContent} = usePopupWithContent()

    useEffect(() => {
        // Вызов метода получения списка всех пользователей
        if (Data.accountId !== "") {
            CampaignsStore.getCampaignsByAccountFromServer(Data.accountId);
            setHeading("Выберите кампанию")
        } else {
            setHeading("Сперва выберите аккаунт")
        }

    }, [Data.accountId])


    useEffect(() => {
        if (Data.accountId && CampaignsStore.campaigns.length === 0) {
            setEmptyCampaignsList(true)
            setHeading("Нет созданных кампаний")
        } else {
            setEmptyCampaignsList(false)
            setHeading("Выберите кампанию")
        }
    }, [Data.accountId, CampaignsStore.campaigns])

    useEffect(() => {
        CampaignsStore.clearCampaignsList()
    }, [Data.userId])

    const buttonAddCampaignHandler = () => {
        showMessageWithContent("Создать новую кампанию", <AddCampaignForm/>)
    }

    return (
        <div className="campaignsList singleColumnOnMainPage">
            <div className={"heading"}>{heading}</div>
            <div className={"campaignsListContainer"}>
                {
                    emptyCampaignsList ?
                        <div className={"emptyListMessage"}>Для этого рекламного аккаунта нет созданных рекламных кампаний</div>
                        :
                        CampaignsStore.campaigns.map((camp) => {
                            return <SingleCampaignInList key={camp.id} campaign={camp} />
                        })
                }
            </div>
            {
                Data.userType === UserType.ADMIN && Data.accountId !== "" ?
                    <button className={"addCampaignBtn hoverStyleButton"} onClick={buttonAddCampaignHandler}>Создать новую кампанию</button> : <></>
            }
        </div>
    )
})

export default CampaignsList
