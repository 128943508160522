import "../../styles/header.css"
import {AuthService, UserType} from "../../api";
import {useLocation, useNavigate} from "react-router-dom";
import Data from "../../stores/Data"

const Header = () => {
    const navigation = useNavigate()
    const location = useLocation()

    const isStatisticsPage = location.pathname.startsWith("/statistics")

    const logoutButtonHandler = () => {
        if (localStorage.getItem("accessToken")){
            if (Data.userType === UserType.ADMIN) {
                AuthService.deleteApiAuthAdmin().then(resp => {
                    localStorage.removeItem("accessToken")
                    localStorage.removeItem("refreshToken")
                    Data.clearUserType()
                    navigation("/login")
                }).catch(err => {
                    console.log("logout error " + err)
                })
            } else {
                AuthService.deleteApiAuth().then(resp => {
                    localStorage.removeItem("accessToken")
                    localStorage.removeItem("refreshToken")
                    Data.clearUserType()
                    navigation("/login")
                }).catch(err => {
                    console.log("logout error " + err)
                })
            }

        } else {
            console.log("Logout error: отсутствует AccessToken")
        }
    }

    return (
            <header className="header">
                <div className={"headerContainer"}>
                    <a className="logo" href={"/"}>UnyMark | Умная статистика</a>
                    {isStatisticsPage && <div className={"campaignNameOnHeader"}>{Data.campaignName}</div> }
                    {
                        localStorage.getItem("accessToken") != null ?
                            <div className={"logout"} onClick={logoutButtonHandler}>Выйти из аккаунта</div> : <></>
                    }
                </div>
            </header>
    )
}

export default Header
