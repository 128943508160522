import {useEffect, useState} from "react";
import AdvImagesStore from "../../stores/AdvImagesStore";
import {AdsWithAllParameters} from "../../api";
import AdvVideo from "../advTypes/AdvVideo";
import AdvImage from "../advTypes/AdvImage";

const SingleVkAds = ({ads}: {ads:AdsWithAllParameters}) => {
    const [logoPath, setLogoPath] = useState<string>();

    useEffect(() => {
        const fetchLogoPath = async () => {
            try {
                const link = await AdvImagesStore.getImageFileById(ads.logo.id);
                setLogoPath(link);
            } catch (error) {
                console.error("Error fetching image: ", error)
            }
        }
        fetchLogoPath()
    }, [])

    return (
        <div className={"singleVkAds"}>
            <div className={"singleVkAdsLeft"}>
                <div className={"singleAdsElement"}>
                    <span>Заголовок: </span>
                    {ads.heading}
                </div>
                <div className={"singleAdsElement"}>
                    <span>Короткий текст: </span>
                    {ads.shortText}
                </div>
                <div className={"singleAdsElement"}>
                    <span>Длинный текст: </span>
                    {ads.longText}
                </div>
                <div className={"singleAdsElement"}>
                    <span>Ссылка: </span>
                    <a href={ads.link && !ads.link.startsWith("http") ? `http://${ads.link}` : ads.link || "#"} target="_blank">{ads.link}</a>
                </div>
            </div>
            <div className={"singleVkAdsRight"}>
                {
                    ads.images && ads.images.length > 0 ?
                        <div className={"singleAdsElement imageElement"}>
                            <span>Картинка: </span>
                            {/*<img src={imagePath} alt={""}/>*/}
                            <AdvImage data={ads.images[0]}/>
                        </div> :
                        <></>
                }
                {
                    ads.logo ?
                        <div className={"singleAdsElement imageElement logoElement"}>
                            <span>Лого: </span>
                            {/*<img src={logoPath} alt={ads.logo.name ? ads.logo.name : ""}/>*/}
                            <AdvImage data={ads.logo}/>
                        </div> :
                        <></>
                }
                {
                    ads.video ?
                        <div className={"singleAdsElement"}>
                            <span>Видео: </span>
                            <AdvVideo data={ads.video}/>
                        </div> :
                        <></>
                }
            </div>

        </div>
    )
}

export default SingleVkAds
