import {AuthService, UserType} from "./api";
import Data from "../src/stores/Data"
export const refreshToken = async (): Promise<boolean> => {
        let result = false;
        if (localStorage.getItem("refreshToken")) {

            const refToken = localStorage.getItem("refreshToken") || undefined;
            if (Data.userType && Data.userType === UserType.ADMIN) {
                await AuthService.postApiAuthRefreshAdmin({refreshToken: refToken}).then(resp => {
                    if (resp.accessToken && resp.refreshToken) {
                        localStorage.setItem("accessToken", resp.accessToken);
                        localStorage.setItem("refreshToken", resp.refreshToken);
                        result = true;
                    } else {
                        localStorage.removeItem("accessToken")
                        localStorage.removeItem("refreshToken")
                        result = false;
                    }
                }).catch(err => {
                    localStorage.removeItem("accessToken")
                    localStorage.removeItem("refreshToken")
                    result = false;
                })
            } else {
                await AuthService.postApiAuthRefresh({refreshToken: refToken}).then(resp => {
                    if (resp.accessToken && resp.refreshToken) {
                        localStorage.setItem("accessToken", resp.accessToken);
                        localStorage.setItem("refreshToken", resp.refreshToken);
                        result = true;
                    } else {
                        localStorage.removeItem("accessToken")
                        localStorage.removeItem("refreshToken")
                        result = false;
                    }
                }).catch(err => {
                    localStorage.removeItem("accessToken")
                    localStorage.removeItem("refreshToken")
                    result = false;
                })
            }

        } else {
            result = false
        }
    return result;
};
