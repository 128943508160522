import {observer} from "mobx-react";
import Data from "../stores/Data";
import React, {useEffect, useState} from "react";
import SingleAccount from "./SingleAccount";
import "../styles/accountsList.css"
import AddAccountForm from "./AddAccountForm";
import AccountsStore from "../stores/AccountsStore";
import {usePopupWithContent} from "./PopupWithContent";
import {UserType} from "../api";

const AccountsList = observer(() => {

    const [heading, setHeading] = useState<string>("")
    const [emptyAccountList, setEmptyAccountList] = useState<boolean>(false);
    const {showMessageWithContent} = usePopupWithContent()

    useEffect(() => {
        // Вызов метода получения списка всех пользователей
        if (Data.userId !== "") {
            AccountsStore.getAllAccountsByUser(Data.userId)
            setHeading("Выберите аккаунт")
        } else {
            setHeading("Сперва выберите пользователя")
        }

    }, [Data.userId])

    useEffect(() => {
        if (Data.userId && AccountsStore.accounts.length === 0) {
            setEmptyAccountList(true);
            setHeading("Нет созданный рекламных аккаунтов")
        } else {
            setEmptyAccountList(false)
            setHeading("Выберите аккаунт")
        }
    }, [AccountsStore.accounts])

    const buttonAddAccountHandler = () => {
        showMessageWithContent("Создать аккаунт", <AddAccountForm/>)
    }

    return (
        <div className="accountsList singleColumnOnMainPage">
            <div className={"heading"}>{heading}</div>
            <div className={"accountsListContainer"}>
                {
                    emptyAccountList ?
                        <div className={"emptyListMessage"}>Пока нет ни одного созданного рекламного аккаунта</div> :
                    AccountsStore.accounts.map((account) => {
                        return <SingleAccount key={account.id} account={account} />
                    })
                }
            </div>
            {
                Data.userType == UserType.ADMIN && Data.userId != ""?
                    <button className={"addAccountBtn hoverStyleButton"} onClick={buttonAddAccountHandler}>Создать новый аккаунт</button> : <></>
            }

        </div>
    )
});

export default AccountsList
