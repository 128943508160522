import {makeAutoObservable, runInAction} from "mobx";
import {CampaignsService, CampaignWithId, CreatableCampaign} from "../api";
import Data from "./Data";

class CampaignsStore {
    campaigns: Array<CampaignWithId> = []

    constructor() {
        makeAutoObservable(this)
    }

    async getCampaignsByAccountFromServer(accountId: string) {
        try {
            const response = await CampaignsService.getApiCampaignGetCampaignsByAccount(Data.accountId);
            runInAction(() => {
                this.campaigns = response;
            });

            return this.campaigns
        } catch (error) {
            console.error("Error: " + error); // Обрабатывайте ошибки при вызове метода
            runInAction(() => {
                this.campaigns = [];
            })
            this.campaigns = [];
            throw false;
        }
    }

    async addCampaign(accountId: string, campaign: CreatableCampaign) {
        try {
            const response = await CampaignsService.postApiCampaignCreateCampaign(accountId, campaign)
            runInAction(() => {
                this.campaigns = [...this.campaigns, response as CampaignWithId]
            })
            return true;
        } catch (error) {
            console.log("Error: " + error)
            throw false;
        }
    }

    clearCampaignsList() {
        this.campaigns = []
    }
}

export default new CampaignsStore()
