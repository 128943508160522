import {StatisticsType, StatisticsWithAllParameters, UserType} from "../api";
import StatisticsStore from "../stores/StatisticsStore";
import Data from "../stores/Data"

interface IStatisticsProps {
    statisticsElement: StatisticsWithAllParameters
}

const SingleStatisticsInTable =  ({statisticsElement}: IStatisticsProps) => {
    //console.log("single statistic element ")
    //console.log(statisticsElement)

    if (statisticsElement.type === StatisticsType.DIRECT_SEARCH_AND_BANNERS) {
        return (
            <DirectSearchAndBannerElement statisticsElement={statisticsElement}/>
        )
    } else if (statisticsElement.type === StatisticsType.DIRECT_MEDIA || statisticsElement.type === StatisticsType.VK) {
        return (
            <DirectMediaAndVKElement statisticsElement={statisticsElement}/>
        )
    } else {
         return <>Неизвестный тип элемента статистики</>
    }
}

const DirectSearchAndBannerElement = ({statisticsElement}: IStatisticsProps) => {
    const removeButtonHandler = () => {
        //console.log("remove direct searchAndBanner statistics element");
        StatisticsStore.removeStatistics(statisticsElement.id);
    }
    return (
        <div className={"singleStatisticsInTable " + statisticsElement.type} data-id={statisticsElement.id}>
            {/*<div className={"statsField"}>{statisticsElement.date}</div>*/}
            <div className={"statsField"}>{new Date(statisticsElement.date).toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' })}</div>
            <div className={"statsField"}>{statisticsElement.views}</div>
            <div className={"statsField"}>{statisticsElement.clicks}</div>
            <div className={"statsField"}>{Number(statisticsElement.CTR).toFixed(2)}</div>
            <div className={"statsField"}>{statisticsElement.cpc}</div>
            <div className={"statsField"}>{statisticsElement.fullCost}</div>
            <div className={"statsField"}>{statisticsElement.avgBid}</div>
            <div className={"statsField"}>{statisticsElement.avgPosOfView}</div>
            <div className={"statsField"}>{statisticsElement.avgPosOfClick}</div>
            <div className={"statsField"}>{statisticsElement.avgTrafficVolume}</div>
            {
                Data.userType === UserType.ADMIN ?<div className={"statsField"}><button onClick={removeButtonHandler} className={"removeStatisticsElement"}>×</button></div> : <></>
            }
        </div>
    )
}

const DirectMediaAndVKElement = ({statisticsElement}: IStatisticsProps) => {
    const removeButtonHandler = () => {
        console.log("remove direct searchAndBanner statistics element");
        StatisticsStore.removeStatistics(statisticsElement.id);
    }
    return (
        <div className={"singleStatisticsInTable " + statisticsElement.type} data-id={statisticsElement.id}>
            {/*<div className={"statsField"}>{statisticsElement.date}</div>*/}
            <div className={"statsField"}>{new Date(statisticsElement.date).toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' })}</div>
            <div className={"statsField"}>{statisticsElement.views}</div>
            <div className={"statsField"}>{statisticsElement.clicks}</div>
            <div className={"statsField"}>{statisticsElement.CTR}</div>
            <div className={"statsField"}>{statisticsElement.cpc}</div>
            <div className={"statsField"}>{statisticsElement.fullCost}</div>
            <div className={"statsField"}>{statisticsElement.costPerThousandViews}</div>
            {
                Data.userType === UserType.ADMIN ?<div className={"statsField"}><button onClick={removeButtonHandler} className={"removeStatisticsElement"}>×</button></div> : <></>
            }
        </div>
    )
}

export default SingleStatisticsInTable
