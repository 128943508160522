import {AdsAndLogoImage} from "../../api";
import {useEffect, useState} from "react";
import {usePopupWithContent} from "../PopupWithContent";
import AdvImagesStore from "../../stores/AdvImagesStore";
import IconPlay from "../../images/icons/playVideo.svg" ;
import IconViews from "../../images/icons/eye.svg";
const AdvVideo: React.FC<{ data: AdsAndLogoImage }> = ({ data }) => {

    const [videoPath, setVideoPath] = useState<string>("");
    const { showMessageWithContent } = usePopupWithContent()

    useEffect(() => {
        const fetchImagePath = async () => {
            try {
                const link = await AdvImagesStore.getImageFileById(data.id);
                setVideoPath(link);
            } catch (error) {
                console.error("Error fetching image: ", error)
            }
        }
        fetchImagePath()
    }, [data.id])

    const videoClickHandler = () => {
        showMessageWithContent(
            " ",
            <div className={"AdvVideoContainer"}>
                <video controls src={videoPath}>Ваш браузер не поддерживает просмотр видео</video>
            </div>
        )
    }
    return (
        <div className={"AdvVideo"} id={data.id} onClick={videoClickHandler}>
            <div className={"AdvVideoContainer"}>
                <div className={"VideoPlayIcon"}>
                    <img src={IconPlay}/>
                    {/*<img className={"totalIcon"} src={IconViews}/>*/}
                </div>
                <video src={videoPath} onClick={(event) => event.preventDefault()}>Ваш браузер не поддерживает просмотр видео</video>
            </div>
        </div>
    );
}

export default AdvVideo
