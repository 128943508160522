import {AggregateStatistic, CampaignType, StatisticsType, StatisticsWithAllParameters} from "../../api";
import SingleGroupStatisticInTable from "./SingleGroupStatisticInTable";
import {useEffect, useState} from "react";
import Data from "../../stores/Data"
import StatisticsStore from "../../stores/StatisticsStore";
import Statistics from "../../pages/Statistics";
import {observer} from "mobx-react";
import StatisticsHeader from "../StatisticsHeader";



const StatisticsFromGroupsByCampaignTable = observer(() => {
    let Headers;
    let currentType: StatisticsType;
    const [statsByGroups, setStatsByGroups] = useState<AggregateStatistic[]>()

    useEffect(() => {

        setStatsByGroups(StatisticsStore.statisticsGroup)

    }, [StatisticsStore.statisticsGroup])

    if (StatisticsStore.statisticsGroup.length === 0) {
        return <>
            Нет статистики за этот период
        </>
    }

    currentType = StatisticsStore.statisticsGroup[0].type;

    switch (currentType){
        case StatisticsType.DIRECT_SEARCH_AND_BANNERS:
            Headers = () => {
                return <div className={"statisticsHeader searchAndBannerHeader "}>
                    <div className={"headerField"}>Группа</div>
                    <div className={"headerField"}>Показы</div>
                    <div className={"headerField"}>Клики</div>
                    <div className={"headerField"}>CTR, %</div>
                    <div className={"headerField"}>CPC, руб.</div>
                    <div className={"headerField"}>Стоимость, руб.</div>
                    <div className={"headerField"}>Ср. ставка, руб.</div>
                    <div className={"headerField"}>Ср. позиция показа</div>
                    <div className={"headerField"}>Ср. позиция клика</div>
                    <div className={"headerField"}>Ср. объем трафика</div>
                </div>
            }
            break;
        case StatisticsType.DIRECT_MEDIA:
        case StatisticsType.VK:
            Headers = () => {
                return <div className={"statisticsHeader mediaAndVkHeader "}>
                    <div className={"headerField"}>Группа</div>
                    <div className={"headerField"}>Показы</div>
                    <div className={"headerField"}>Клики</div>
                    <div className={"headerField"}>CTR, %</div>
                    <div className={"headerField"}>CPC, руб.</div>
                    <div className={"headerField"}>Стоимость, руб.</div>
                    <div className={"headerField"}>Цена за тысячу показов, руб.</div>
                </div>
            }
            break;
        default:
            Headers = () => null;
            break;
    }
    return (
        <div className={"StatisticsByGroupTable"}>

                <Headers/>
            <div className={"statisticsRows"}>
                {
                    StatisticsStore.statisticsGroup.map((item, index) => {
                        return <SingleGroupStatisticInTable statisticsElement={item} key={index}/>
                    })
                }
            </div>

        </div>
    )
})

export default StatisticsFromGroupsByCampaignTable
