import "../styles/statisticsFilter.css"
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import React, {useEffect, useState} from "react";
import Data from "../stores/Data";
import StatisticsStore from "../stores/StatisticsStore";
import {useNavigate} from "react-router-dom";

const StatisticsFilter = () => {

    const navigation = useNavigate();

    const [dateFrom, setDateFrom] = useState<Date | null>(Data.dateFrom ? new Date(Data.dateFrom) : new Date());
    const [dateTo, setDateTo] = useState<Date | null>(Data.dateTo ? new Date(Data.dateTo) : new Date());

    const handleDateFromChange = (date: Date | null) => {
        setDateFrom(date);
        Data.setDateFrom(date ?? new Date());
    };
    const handleDateToChange = (date: Date | null) => {
        setDateTo(date);
        Data.setDateTo(date ?? new Date());
    };

    useEffect(() => {
        // тут мы отслеживаем изменение Data.CampaignID и фильтры по датам

        // при обновлении страницы пропадает campaignId
        // нужно возвращать на этап выбора кампании
        if (!Data.campaignId) {
            navigation("/");
        }

        // обращаемся в стор, чтобы тот подтянул данные с сервера
        const getStatisticsByCampaignId = () => {
            let getStatisticsResult = StatisticsStore.getAllStatisticsFromServer(dateFrom, dateTo);
            getStatisticsResult.then((response) => {
                // if (response) {
                //     console.log("if response")
                //     //setStatistics(response) присваивать ничего не будем, так как вынесли это в отдельный эффект
                // } else {
                //     console.log("else response")
                // }
            }).catch(error => {
                console.log("catch error " + error)
            })
        };

        const getStatisticsFromGroupsByCampaignId = () => {
            let result = StatisticsStore.getStatisticsListGroupsByCampaign(Data.campaignId, dateFrom, dateTo);
            result.then(() => {

            }).catch(error => {
                console.error("error: ", error)
            })
        }



        // Вызов метода получения списка всех статистик
        if (Data.campaignId !== "") {
            //console.log("campaignId !==")
            getStatisticsByCampaignId();
            getStatisticsFromGroupsByCampaignId();
        } else {
            //console.log("campaignId empty")
        }



    }, [Data.campaignId, dateTo, dateFrom, Data.campaignType])

    return (

            <div className={"statisticsFilter"}>
                <div className={"singleFilter"}>
                    {/*<label>От: </label>*/}
                    <DatePicker
                        locale={ru}
                        selected={dateFrom}
                        onChange={handleDateFromChange}
                        placeholderText="Начиная от"
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="Время"
                        dateFormat="dd.MM.yyyy"
                        shouldCloseOnSelect={true}
                    />
                </div>
                <div className={"singleFilter"}>
                    {/*<label>По: </label>*/}
                    <DatePicker
                        locale={ru}
                        selected={dateTo}
                        onChange={handleDateToChange}
                        placeholderText="Заканчивая по"
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="Время"
                        dateFormat="dd.MM.yyyy"
                        shouldCloseOnSelect={true}
                    />
                </div>
            </div>
    )
}

export default StatisticsFilter
