import React from "react";
import DataTableAddForm from "./DataTableAddForm";
import {UserType} from "../api";
import StatisticsStore from "../stores/StatisticsStore";
import Data from "../stores/Data";
import SingleStatisticsInTable from "./SingleStatisticsInTable";
import StatisticsHeader from "./StatisticsHeader";
import {observer} from "mobx-react";
import 'react-datepicker/dist/react-datepicker.css';
import "../styles/statisticsTable.css"
import {usePopupWithContent} from "./PopupWithContent";
import StatisticsFilter from "./StatisticsFilter";



const StatisticsTable = observer(() => {

    const { showMessageWithContent } = usePopupWithContent()

    return (
        <>
            <h2>Статистика по датам</h2>
            <StatisticsFilter/>
            <div className={`statisticsTable ${Data.userType === UserType.ADMIN ? 'isAdmin' : ''}`}>
                {Data.userType === UserType.ADMIN ?
                    <button className={"hoverStyleButton addStatisticsButton"} onClick={() => {
                        showMessageWithContent('Добавить новый элемент', <DataTableAddForm/>)
                    }}>Добавить статистику</button> : <></>
                }


                <StatisticsHeader type={Data.campaignType} />
                <div className={"statisticsRows"}>
                    {
                        StatisticsStore.statistics.length > 0 ?
                            StatisticsStore.statistics.map((item, index) => {
                                return <SingleStatisticsInTable key={index} statisticsElement={item}/>
                            })
                            : <div className={"noElementsInStatisticTable"}>За указанный период нет статистики. Попробуйте изменить настройки фильтра или выбрать другую рекламную кампанию.</div>
                    }
                </div>
            </div>
        </>

    )
})

export default StatisticsTable
