import StatisticsTable from "../components/StatisticsTable";
import Grafik from "../components/Grafik";
import Header from "../components/header/Header";
import "../styles/mainStyles.css"
import "../styles/statistics.css"
import StatisticsTotal from "../components/StatisticsTotal";
import {useEffect, useState} from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import "../styles/statisticsTabs.css"
import {Helmet} from "react-helmet-async";
import StatisticsFromGroupsByCampaignTable from "../components/statistics/StatisticsFromGroupsByCampaignTable";
import Data from "../stores/Data";
import {observer} from "mobx-react";
import CloseIcon from "../images/icons/close.svg";
import StatisticsAds from "../components/statistics/StatisticsAds";
import StatisticsStore from "../stores/StatisticsStore";

const RemoveGroupFromAdsList = () => {
    const closeClickHandler = () => {
        Data.setGroupId("")
        Data.setGroupName("")
    }
    return <div className={"removeGroupFromAdsList"} onClick={closeClickHandler}>
        <img src={CloseIcon}/>
    </div>
}

const Statistics = observer(() => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0); // Состояние для отслеживания выбранной вкладки
    const [adsTabName, setAdsTabName] = useState<string>("");
    const [showCloseButton, setShowCloseButton] = useState<boolean>(false)

    const openSecondTab = () => {
        setSelectedTabIndex(1); // Выбираем вторую вкладку
        setAdsTabName(": " + Data.groupName)
    }

    //открываем вкладку с объявлениями, если groupId не пустое и оно поменялось
    useEffect(() => {
        if (Data.groupId != "")
            openSecondTab()
    }, [Data.groupId])

    // меняем имя вкладки, если оно не пустое и поменялось
    useEffect(()=> {
        if (Data.groupName != "") {
            setAdsTabName(": " + Data.groupName)
            setShowCloseButton(true)
            const timeoutId = setTimeout(() => {
                const pageWrapperElement = document.querySelector(".pageWrapper");
                if (pageWrapperElement) {
                    const contentHeight = pageWrapperElement.scrollHeight;
                    const wrapperHeight = pageWrapperElement.clientHeight;
                    const scrollTo = contentHeight - wrapperHeight;
                    pageWrapperElement.scrollTo({ top: scrollTo, behavior: "smooth" });
                }
            }, 500);
            return () => clearTimeout(timeoutId);

        } else {
            setAdsTabName("")
            setShowCloseButton(false)
            StatisticsStore.clearStatisticsByGroup()
        }
    }, [Data.groupName])



    return (
        <>
            <Helmet>
                <title>Просмотр статистики | UnyMark</title>
            </Helmet>
            <Header/>
            <div className="statistics pageWrapper">
                <div className={"container"}>
                    <StatisticsTotal/>

                    <Tabs>
                        <TabList>
                            <Tab>Таблица</Tab>
                            <Tab>Графики</Tab>
                        </TabList>
                        <TabPanel>
                            <StatisticsTable/>
                        </TabPanel>
                        <TabPanel>
                            <Grafik/>
                        </TabPanel>
                    </Tabs>
                    <Tabs className={"statisticsTabs react-tabs"} selectedIndex={selectedTabIndex} onSelect={index => setSelectedTabIndex(index)}>
                        <TabList>
                            <Tab>Статистика по группам</Tab>
                            <Tab>Статистика по объявлениям{adsTabName}{showCloseButton ? <RemoveGroupFromAdsList/> : <></>}</Tab>
                        </TabList>
                        <TabPanel>
                            <StatisticsFromGroupsByCampaignTable/>
                        </TabPanel>
                        <TabPanel>
                            <StatisticsAds />
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </>

    )
})

export default Statistics
