/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdsAndLogoImage } from '../models/AdsAndLogoImage';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ImageService {

    /**
     * @param user 
     * @param formData 
     * @returns AdsAndLogoImage Success
     * @throws ApiError
     */
    public static postApiImagesUpload(
user?: string,
formData?: {
File?: Blob;
},
): CancelablePromise<AdsAndLogoImage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/images/upload',
            query: {
                'user': user,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param id 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiImagesGetImage(
id?: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/images/getImage',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param userId 
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static getApiImagesGetImageContent(
userId: string,
id?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/images/{userId}/getImageContent',
            path: {
                'userId': userId,
            },
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns AdsAndLogoImage Success
     * @throws ApiError
     */
    public static getApiImagesGetAllImagesByUser(
id?: string,
): CancelablePromise<Array<AdsAndLogoImage>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/images/getAllImagesByUser',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns AdsAndLogoImage Success
     * @throws ApiError
     */
    public static getApiImagesGetImageFromAds(
id?: string,
): CancelablePromise<Array<AdsAndLogoImage>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/images/getImageFromAds',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns AdsAndLogoImage Success
     * @throws ApiError
     */
    public static getApiImagesGetImagesListByGroup(
id?: string,
): CancelablePromise<Array<AdsAndLogoImage>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/images/getImagesListByGroup',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns AdsAndLogoImage Success
     * @throws ApiError
     */
    public static getApiImagesGetImagesListByCampaign(
id?: string,
): CancelablePromise<Array<AdsAndLogoImage>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/images/getImagesListByCampaign',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns AdsAndLogoImage Success
     * @throws ApiError
     */
    public static getApiImagesGetLogoFromVkAds(
id?: string,
): CancelablePromise<AdsAndLogoImage> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/images/getLogoFromVkAds',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns AdsAndLogoImage Success
     * @throws ApiError
     */
    public static getApiImagesGetVideoFromAds(
id?: string,
): CancelablePromise<Array<AdsAndLogoImage>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/images/getVideoFromAds',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns AdsAndLogoImage Success
     * @throws ApiError
     */
    public static getApiImagesGetVideoListByGroup(
id?: string,
): CancelablePromise<Array<AdsAndLogoImage>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/images/getVideoListByGroup',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns AdsAndLogoImage Success
     * @throws ApiError
     */
    public static getApiImagesGetVideoListByCampaign(
id?: string,
): CancelablePromise<Array<AdsAndLogoImage>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/images/getVideoListByCampaign',
            query: {
                'id': id,
            },
        });
    }

}
