import {Account, AccountType} from "../api";
import "../styles/singleAccount.css"
import Data from "../stores/Data";
import {useEffect, useState} from "react";
import {observer} from "mobx-react";
import AdvImagesStore from "../stores/AdvImagesStore";
import YandexLogo from "../images/adsSystems/yandex_logo.png"
import VkLogo from "../images/adsSystems/vk_logo.png";

interface IAccountProps {
    account: Account
}

const SingleAccount =  observer(({account}: IAccountProps) => {
    const [activeClass, setActiveClass] = useState("")
    const [logoBrandPath, setLogoBrandPath] = useState("")
    let currentSystemImage;

    const singleAccountButtonHandler = () => {
        Data.setAccountId(account.id);
        Data.setAccountType(account.type)
    }

    const fetchImagesPaths = async () => {
        try {
            if (account.logoBrand)
            {
                const link = await AdvImagesStore.getImageFileById(account.logoBrand);
                setLogoBrandPath(link);
            }
        } catch (error) {
            console.error("Error fetching image: ", error)
        }
    }
    fetchImagesPaths()

    switch (account.type) {
        case AccountType.YANDEX_DIRECT:
            currentSystemImage = YandexLogo;
            break;
        case AccountType.VK:
            currentSystemImage = VkLogo;
            break;
    }

    useEffect(() => {
        if (account.id === Data.accountId) {
            setActiveClass("active")
        } else {
            setActiveClass("")
        }
    }, [Data.accountId])

    return (
        <div className={"singleAccount listElement " + activeClass} data-account={account.id} onClick={singleAccountButtonHandler}>
            <div className={"accountInfo"}>
                <div className="accountType"><strong>Аккаунт:</strong> {account.type}</div>
                <div className="accountName"><strong>Название:</strong> {account.name}</div>
                <div className="accountLogin"><strong>Логин:</strong> {account.login}</div>
            </div>
            <div className={"accountImages"}>
                {
                    logoBrandPath ? <img className="brandLogo" src={logoBrandPath} alt={account.type} /> : <></>
                }
                <img className="adsSystemLogo" src={currentSystemImage} alt={account.type} />
            </div>

        </div>
    )
})

export default SingleAccount
