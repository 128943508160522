import {observer} from "mobx-react";
import React, {useState} from "react";
import Select, {ActionMeta} from "react-select";
import {AccountType, CreatableAccount} from "../api";
import AccountsStore from "../stores/AccountsStore";
import Data from "../stores/Data";
import {usePopup} from "./PopupMessage";
import {usePopupWithContent} from "./PopupWithContent";
import "../styles/addAccountForm.css"

const AddAccountForm = observer(() => {
    const options = [
        {
            value: AccountType.YANDEX_DIRECT,
            label: "Яндекс Директ"
        },
        {
            value: AccountType.VK,
            label: "ВКонтакте"
        }
    ]

    const { showMessage } = usePopup();

    const [accountType, setAccountType] = useState<AccountType | null>()
    const [accountName, setAccountName] = useState<string>("")
    const [accountLogin, setAccountLogin] = useState<string>("")

    const typeSelectChange = (selectedOption: { value: AccountType; label: string } | null, actionMeta: ActionMeta<{ value: AccountType; label: string }>) => {
        if (selectedOption) {
            setAccountType(selectedOption.value);
        } else {
            // Обработка, если выбрано пустое значение (null)
            setAccountType(null);
        }
    };

    const {closeMessage} = usePopupWithContent()

    const addAccountBtnHandler = () => {
        if (accountLogin?.length === 0 || accountName?.length === 0 || accountType === null || accountType === undefined) {
            console.log("Заполните все поля")
            showMessage("Заполните форму","Перед созданием аккаунта необходимо указать его имя, логин и выбрать необходимый тип данных")
        } else {
            const creatableAccount: CreatableAccount = {
                login: accountLogin || '',
                name: accountName || '',
                type: accountType || AccountType.YANDEX_DIRECT
            }
            AccountsStore.addAccount(Data.userId, creatableAccount)
            setAccountName("")
            setAccountType(undefined)
            setAccountLogin("")
            closeMessage()
        }
    }

    return (
        <div className={"AddAccountForm"}>
            <label>
                <span>Логин: </span>
                <input onChange={(event) => setAccountLogin(event.target.value)} type={"text"} value={accountLogin ? accountLogin : ""}/>
            </label>
            <label>
                <span>Название аккаунта: </span>
                <input onChange={(event) => setAccountName(event.target.value)} type={"text"} value={accountName ? accountName : ""}/>
            </label>

            <label>
                <span>Тип аккаунта: </span>
                <Select
                    className="accountTypeSelect"
                    options={options}
                    isSearchable={false}
                    name="accountType"
                    placeholder="Тип аккаунта"
                    onChange = {typeSelectChange}
                    value={accountType ? { value: accountType, label: accountType.toString() } : null}
                />
            </label>
            <button
                onClick={addAccountBtnHandler}
                className={"hoverStyleButton"}
            >Добавить</button>
        </div>
    )
})

export default AddAccountForm
