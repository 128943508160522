/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AggregateStatistic } from '../models/AggregateStatistic';
import type { CreatableStatistics } from '../models/CreatableStatistics';
import type { EditableStatistics } from '../models/EditableStatistics';
import type { StatisticsType } from '../models/StatisticsType';
import type { StatisticsWithAllParameters } from '../models/StatisticsWithAllParameters';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StatisticsService {

    /**
     * @param ads 
     * @param type 
     * @param costPerThousandViews 
     * @param avgPosOfView 
     * @param avgPosOfClick 
     * @param avgTrafficVolume 
     * @param requestBody 
     * @returns CreatableStatistics Success
     * @throws ApiError
     */
    public static postApiStatisticsCreateStatistics(
ads?: string,
type?: StatisticsType,
costPerThousandViews?: number,
avgPosOfView?: number,
avgPosOfClick?: number,
avgTrafficVolume?: number,
requestBody?: CreatableStatistics,
): CancelablePromise<CreatableStatistics> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/statistics/createStatistics',
            query: {
                'ads': ads,
                'type': type,
                'costPerThousandViews': costPerThousandViews,
                'avgPosOfView': avgPosOfView,
                'avgPosOfClick': avgPosOfClick,
                'avgTrafficVolume': avgTrafficVolume,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns CreatableStatistics Success
     * @throws ApiError
     */
    public static getApiStatisticsGetStatistics(
id?: string,
): CancelablePromise<CreatableStatistics> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/statistics/getStatistics',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param ads 
     * @param dateFrom 
     * @param dateTo 
     * @returns StatisticsWithAllParameters Success
     * @throws ApiError
     */
    public static getApiStatisticsGetStatisticsListByAds(
ads?: string,
dateFrom?: string,
dateTo?: string,
): CancelablePromise<Array<StatisticsWithAllParameters>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/statistics/getStatisticsListByAds',
            query: {
                'ads': ads,
                'dateFrom': dateFrom,
                'dateTo': dateTo,
            },
        });
    }

    /**
     * @param id 
     * @param name 
     * @param type 
     * @param dateFrom 
     * @param dateTo 
     * @returns AggregateStatistic Success
     * @throws ApiError
     */
    public static getApiStatisticsGetAggregateStatisticsByAds(
id?: string,
name?: string,
type?: StatisticsType,
dateFrom?: string,
dateTo?: string,
): CancelablePromise<AggregateStatistic> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/statistics/getAggregateStatisticsByAds',
            query: {
                'id': id,
                'name': name,
                'type': type,
                'dateFrom': dateFrom,
                'dateTo': dateTo,
            },
        });
    }

    /**
     * @param id 
     * @param dateFrom 
     * @param dateTo 
     * @returns AggregateStatistic Success
     * @throws ApiError
     */
    public static getApiStatisticsGetStatisticsListFromAdsByGroup(
id?: string,
dateFrom?: string,
dateTo?: string,
): CancelablePromise<Array<AggregateStatistic>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/statistics/getStatisticsListFromAdsByGroup',
            query: {
                'id': id,
                'dateFrom': dateFrom,
                'dateTo': dateTo,
            },
        });
    }

    /**
     * @param id 
     * @param name 
     * @param type 
     * @param dateFrom 
     * @param dateTo 
     * @returns AggregateStatistic Success
     * @throws ApiError
     */
    public static getApiStatisticsGetAggregateStatisticByGroup(
id?: string,
name?: string,
type?: StatisticsType,
dateFrom?: string,
dateTo?: string,
): CancelablePromise<AggregateStatistic> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/statistics/getAggregateStatisticByGroup',
            query: {
                'id': id,
                'name': name,
                'type': type,
                'dateFrom': dateFrom,
                'dateTo': dateTo,
            },
        });
    }

    /**
     * @param id 
     * @param dateFrom 
     * @param dateTo 
     * @returns AggregateStatistic Success
     * @throws ApiError
     */
    public static getApiStatisticsGetStatisticsListFromGroupByCampaign(
id?: string,
dateFrom?: string,
dateTo?: string,
): CancelablePromise<Array<AggregateStatistic>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/statistics/getStatisticsListFromGroupByCampaign',
            query: {
                'id': id,
                'dateFrom': dateFrom,
                'dateTo': dateTo,
            },
        });
    }

    /**
     * @param id 
     * @param dateFrom 
     * @param dateTo 
     * @returns StatisticsWithAllParameters Success
     * @throws ApiError
     */
    public static getApiStatisticsGetAggregateStatisticByCampaign(
id?: string,
dateFrom?: string,
dateTo?: string,
): CancelablePromise<StatisticsWithAllParameters> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/statistics/getAggregateStatisticByCampaign',
            query: {
                'id': id,
                'dateFrom': dateFrom,
                'dateTo': dateTo,
            },
        });
    }

    /**
     * @param id 
     * @param dateFrom 
     * @param dateTo 
     * @returns AggregateStatistic Success
     * @throws ApiError
     */
    public static getApiStatisticsGetStatisticsListFromAdsByCampaign(
id?: string,
dateFrom?: string,
dateTo?: string,
): CancelablePromise<Array<AggregateStatistic>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/statistics/getStatisticsListFromAdsByCampaign',
            query: {
                'id': id,
                'dateFrom': dateFrom,
                'dateTo': dateTo,
            },
        });
    }

    /**
     * @param id 
     * @param dateFrom 
     * @param dateTo 
     * @returns StatisticsWithAllParameters Success
     * @throws ApiError
     */
    public static getApiStatisticsGetStatisticsByGroup(
id?: string,
dateFrom?: string,
dateTo?: string,
): CancelablePromise<StatisticsWithAllParameters> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/statistics/getStatisticsByGroup',
            query: {
                'id': id,
                'dateFrom': dateFrom,
                'dateTo': dateTo,
            },
        });
    }

    /**
     * @param id 
     * @param dateFrom 
     * @param dateTo 
     * @returns StatisticsWithAllParameters Success
     * @throws ApiError
     */
    public static getApiStatisticsGetStatisticsByCampaign(
id?: string,
dateFrom?: string,
dateTo?: string,
): CancelablePromise<StatisticsWithAllParameters> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/statistics/getStatisticsByCampaign',
            query: {
                'id': id,
                'dateFrom': dateFrom,
                'dateTo': dateTo,
            },
        });
    }

    /**
     * @param id 
     * @param costPerThousandViews 
     * @param avgPosOfView 
     * @param avgPosOfClick 
     * @param avgTrafficVolume 
     * @param requestBody 
     * @returns CreatableStatistics Success
     * @throws ApiError
     */
    public static patchApiStatisticsUpdateStatistics(
id?: string,
costPerThousandViews?: number,
avgPosOfView?: number,
avgPosOfClick?: number,
avgTrafficVolume?: number,
requestBody?: EditableStatistics,
): CancelablePromise<CreatableStatistics> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/statistics/updateStatistics',
            query: {
                'id': id,
                'costPerThousandViews': costPerThousandViews,
                'avgPosOfView': avgPosOfView,
                'avgPosOfClick': avgPosOfClick,
                'avgTrafficVolume': avgTrafficVolume,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiStatisticsDeleteStatistics(
id?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/statistics/deleteStatistics',
            query: {
                'id': id,
            },
        });
    }

}
