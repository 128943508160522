import StatisticsStore from "../stores/StatisticsStore";
import {observer} from "mobx-react";
import "../styles/grafikComponent.css";
import {Bar, Line} from "react-chartjs-2";
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from 'chart.js';

import {useEffect, useState} from "react";
import Data from "../stores/Data";
import {CampaignType} from "../api";


const ChartTitleColor: string = "#efefef"
const ChartAxisTextColor: string = "#ddd";
const ChartLabelTextColor: string ="#efefef";
const ChartGridColor: string = "rgba(255,255,255,0.1)"

const ChartColors: string[] = [
    '#FF6B6B', // Красный
    '#FFD166', // Жёлтый
    '#06D6A0', // Зелёный
    '#118AB2', // Голубой
    '#BC5090', // Розовый
    '#8338EC', // Фиолетовый
    '#FF6F61', // Оранжевый
    '#FF9F1C', // Другой оттенок оранжевого
    '#FFD700', // Золотой
]

// интерфейс ProcessedData нужен, чтобы проходить за один раз весь цикл StatisticsStore
// и вытаскивать из него значения сразу во все стейты
// (вместо того, чтобы для каждого стейта использовать map)
interface ProcessedData {
    dates: string[];
    views: number[];
    cpcs: number[];
    clicks: number[];
    bid: number[];
    fullCost: number[];
    ctr: number[];
    avgPosOfView: number[];
    avgPosOfClick: number[];
    avgTrafficVolume: number[];
    avgCostPerThousandViews: number[];
}

// начальные параметры для датасетов графиков
const initialDataForChartLine: { labels: string[], datasets: any[] } = {
    labels: [],
    datasets: [
        {
            label: '',
            data:  [],
        },
    ],
};

// начальные параметры для опций графиков
const initialOptionsForCharts = {
    responsive: true,
    interaction: {
        intersect: false,
        mode: 'index' as 'index',
    },
    plugins: {
        legend: {
            position: 'top' as const,
            display: false,
            labels : {
                color: ChartLabelTextColor,
                font: {
                    size: 14,
                    family: "Exo 2, sans-serif"
                }
            }
        },
        title: {
            display: true,
            text: '',
            color: ChartTitleColor,
            font: {
                size: 14
            }
        },
    },
    scales: {
        x: {
            ticks: {
                color: ChartAxisTextColor
            },
            grid: {
                color: ChartGridColor
            }
        },
        y: {
            ticks: {
                color: ChartAxisTextColor
            },
            grid: {
                color: ChartGridColor
            }
        }
    }
};


const Grafik = observer(() => {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        BarElement,
        Filler
    );


    // стейты со значениями столбцов
    const [dates, setDates] = useState<string[]>([]);
    const [views, setViews] = useState<number[]>([]);
    const [clicks, setClicks] = useState<number[]>([]);
    const [cpcs, setCpcs] = useState<number[]>([]);
    const [bid, setBid] = useState<number[]>([]);
    const [ctr, setCtr] = useState<number[]>([]);
    const [fullCost, setFullCost] = useState<number[]>([]);
    const [avgPosOfView, setAvgPosOfView] = useState<number[]>([]);
    const [avgPosOfClick, setAvgPosOfClick] = useState<number[]>([]);
    const [avgTrafficVolume, setAvgTrafficVolume] = useState<number[]>([]);
    const [avgCostPerThousandViews, setAvgCostPerThousandViews] = useState<number[]>([]);

    // хранение данных по каждому столбцу в статистике (тут в формате нужном для графиков)
    const [chartsDataViews, setChartsDataViews] = useState(initialDataForChartLine)
    const [chartsDataClicks, setChartsDataClicks] = useState(initialDataForChartLine)
    const [chartsDataCpcs, setChartsDataCpcs] = useState(initialDataForChartLine)
    const [chartsDataCtr, setChartsDataCtr] = useState(initialDataForChartLine)
    const [chartsDataFullCost, setChartsDataFullCost] = useState(initialDataForChartLine)
    const [chartsDataAvgPosOfViewAndClick, setChartsDataAvgPosOfViewAndClick] = useState(initialDataForChartLine)
    const [chartsDataAvgTrafficVolume, setChartsDataAvgTrafficVolume] = useState(initialDataForChartLine)
    const [chartsDataAvgCostPerThousandViews, setChartsDataAvgCostPerThousandViews ] = useState(initialDataForChartLine)

    // хранение опций по каждому столбцу (нужно для замены заголовков и включения легенды)
    const [chartOptionsViews, setChartOptionsViews] = useState(initialOptionsForCharts)
    const [chartOptionsClicks, setChartOptionsClicks] = useState(initialOptionsForCharts)
    const [chartOptionsCpc, setChartOptionsCpc] = useState(initialOptionsForCharts)
    const [chartOptionsFullCost, setChartOptionsFullCost] = useState(initialOptionsForCharts)
    const [chartOptionsCtr, setChartOptionsCtr] = useState(initialOptionsForCharts)
    const [chartOptionsAvgPosOfViewAndClick, setChartOptionsAvgPosOfViewAndClick] = useState(initialOptionsForCharts)
    const [chartOptionsAvgTrafficVolume, setChartOptionsAvgTrafficVolume] = useState(initialOptionsForCharts)
    const [chartOptionsAvgCostPerThousandViews, setChartOptionsAvgCostPerThousandViews] = useState(initialOptionsForCharts)

    // нужен для отслеживания ресайза экрана
    const [redrawKey, setRedrawKey] = useState<number>(0)

    const handleResize = () => {
        setRedrawKey((prevKey) => prevKey + 1)
    }

    // получаем данные из стора в стейты
    const getDataFromStoreToStates = () => {
        const processedData: ProcessedData = StatisticsStore.statistics.reduce(
            (acc: ProcessedData, item) => {
                const processedItemDate = new Date(item.date).toLocaleDateString('ru-RU', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                });
                acc.dates.push(processedItemDate);
                acc.views.push(item.views);
                acc.cpcs.push(item.cpc);
                acc.clicks.push(item.clicks);
                acc.bid.push(item.avgBid);
                acc.fullCost.push(item.fullCost);
                acc.ctr.push(item.CTR);
                acc.avgPosOfView.push(item.avgPosOfView || 0);
                acc.avgPosOfClick.push(item.avgPosOfClick || 0);
                acc.avgTrafficVolume.push(item.avgTrafficVolume || 0);
                acc.avgCostPerThousandViews.push(item.costPerThousandViews || 0);
                return acc;
            },
            {
                dates: [],
                views: [],
                cpcs: [],
                clicks: [],
                bid: [],
                fullCost: [],
                ctr: [],
                avgPosOfView: [],
                avgPosOfClick: [],
                avgTrafficVolume: [],
                avgCostPerThousandViews: [],
            }
        );

        setDates(processedData.dates);
        setViews(processedData.views);
        setCpcs(processedData.cpcs);
        setClicks(processedData.clicks);
        setBid(processedData.bid);
        setFullCost(processedData.fullCost);
        setCtr(processedData.ctr);
        setAvgPosOfView(processedData.avgPosOfView);
        setAvgPosOfClick(processedData.avgPosOfClick);
        setAvgTrafficVolume(processedData.avgTrafficVolume);
        setAvgCostPerThousandViews(processedData.avgCostPerThousandViews);
    }

    // получаем данные при обновлении стора статистик
    useEffect(() => {
        getDataFromStoreToStates()
    }, [StatisticsStore.statistics])

    // при изменении локальных стейтов меняем данные в графиках
    useEffect(() => {
        updateChartsData();
    }, [views, dates, cpcs, clicks])

    useEffect(() => {
        // получаем данные при монтировании компонента
        getDataFromStoreToStates()
        handleResize()
        // Подписка на событие изменения размеров окна
        window.addEventListener('resize', handleResize);

        // Отписка при размонтировании компонента
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    const updateChartsData = () => {
        //console.log("updateChartsData")
        // подставляем данные в каждый график
        setChartsDataViews({
            ...chartsDataViews,
            labels: dates,
            datasets: [
                {
                    ...chartsDataViews.datasets[0],
                    data: views,
                    label: 'Показы',
                    backgroundColor: ChartColors[0],
                    borderColor: ChartColors[0]
                },
            ],
        });
        setChartsDataClicks({
            ...chartsDataClicks,
            labels: dates,
            datasets: [
                {
                    ...chartsDataClicks.datasets[0],
                    data: clicks,
                    label: 'Клики',
                    backgroundColor: ChartColors[1],
                    borderColor: ChartColors[1]
                },
            ],
        });
        setChartsDataCpcs({
            ...chartsDataCpcs,
            labels: dates,
            datasets: [
                {
                    ...chartsDataCpcs.datasets[0],
                    data: cpcs,
                    label: 'CPC',
                    backgroundColor: ChartColors[2],
                    borderColor: ChartColors[2]
                },
                {
                    ...chartsDataCpcs.datasets[1],
                    data: bid,
                    label: 'Ср. ставка',
                    backgroundColor: ChartColors[3],
                    borderColor: ChartColors[3]
                }
            ],
        });

        setChartsDataFullCost({
            ...chartsDataFullCost,
            labels: dates,
            datasets: [
                {
                    ...chartsDataFullCost.datasets[0],
                    data: fullCost,
                    label: 'Общая стоимость',
                    backgroundColor: ChartColors[4],
                    borderColor: ChartColors[4]
                },
            ],
        });

        setChartsDataCtr({
            ...chartsDataCtr,
            labels: dates,
            datasets: [
                {
                    ...chartsDataCtr.datasets[0],
                    data: ctr,
                    label: 'CTR',
                    backgroundColor: ChartColors[5],
                    borderColor: ChartColors[5]
                },
            ],
        });

        setChartsDataAvgPosOfViewAndClick({
            ...chartsDataAvgPosOfViewAndClick,
            labels: dates,
            datasets: [
                {
                    ...chartsDataAvgPosOfViewAndClick.datasets[0],
                    data: avgPosOfView,
                    label: 'Ср. позиция показа',
                    backgroundColor: ChartColors[6],
                    borderColor: ChartColors[6]
                },
                {
                    ...chartsDataAvgPosOfViewAndClick.datasets[1],
                    data: avgPosOfClick,
                    label: 'Ср. позиция клика',
                    backgroundColor: ChartColors[7],
                    borderColor: ChartColors[7]
                },
            ],
        });

        setChartsDataAvgTrafficVolume({
            ...chartsDataAvgTrafficVolume,
            labels: dates,
            datasets: [
                {
                    ...chartsDataAvgTrafficVolume.datasets[0],
                    data: avgTrafficVolume,
                    label: 'Ср. объем трафика',
                    backgroundColor: ChartColors[8],
                    borderColor: ChartColors[8]
                },
            ],
        });

        setChartsDataAvgCostPerThousandViews( {
            ...chartsDataAvgCostPerThousandViews,
            labels: dates,
            datasets: [
                {
                    ...chartsDataAvgCostPerThousandViews.datasets[0],
                    data: avgCostPerThousandViews,
                    label: 'Ср. цена за 1 тыс. показов',
                    backgroundColor: ChartColors[7],
                    borderColor: ChartColors[7]
                }
            ]
        })


        /*
        *   подставляем опции для каждого графика
        * */
        setChartOptionsViews({
            ...chartOptionsViews,
            plugins: {
                ...chartOptionsViews.plugins,
                title: {
                    ...chartOptionsViews.plugins.title,
                    text: "Количество показов",
                }
            }
        })

        setChartOptionsClicks({
            ...chartOptionsClicks,
            plugins: {
                ...chartOptionsClicks.plugins,
                title: {
                    ...chartOptionsClicks.plugins.title,
                    text: "Количество кликов",
                }
            }
        })

        setChartOptionsCpc({
            ...chartOptionsCpc,
            plugins: {
                ...chartOptionsCpc.plugins,
                title: {
                    ...chartOptionsCpc.plugins.title,
                    text: "Стоимость клика и ср. ставка"
                },
                legend: {
                    ...chartOptionsCpc.plugins.legend,
                    display: true
                }
            }
        })

        setChartOptionsFullCost({
            ...chartOptionsFullCost,
            plugins: {
                ...chartOptionsFullCost.plugins,
                title: {
                    ...chartOptionsFullCost.plugins.title,
                    text: "Общая стоимость"
                },
                // legend: {
                //     ...chartOptionsCpc.plugins.legend,
                //     display: true
                // }
            }
        })

        setChartOptionsCtr({
            ...chartOptionsCtr,
            plugins: {
                ...chartOptionsCtr.plugins,
                title: {
                    ...chartOptionsCtr.plugins.title,
                    text: "CTR"
                },
                // legend: {
                //     ...chartOptionsCpc.plugins.legend,
                //     display: true
                // }
            }
        })

        setChartOptionsAvgPosOfViewAndClick({
            ...chartOptionsAvgPosOfViewAndClick,
            plugins: {
                ...chartOptionsAvgPosOfViewAndClick.plugins,
                title: {
                    ...chartOptionsAvgPosOfViewAndClick.plugins.title,
                    text: "Ср. позиция показа и клика"
                },
                legend: {
                    ...chartOptionsAvgPosOfViewAndClick.plugins.legend,
                    display: true
                }
            }
        })
        setChartOptionsAvgTrafficVolume({
            ...chartOptionsAvgTrafficVolume,
            plugins: {
                ...chartOptionsAvgTrafficVolume.plugins,
                title: {
                    ...chartOptionsAvgTrafficVolume.plugins.title,
                    text: "Ср. объём трафика"
                },
            }
        })

        setChartOptionsAvgCostPerThousandViews({
            ...chartOptionsAvgCostPerThousandViews,
            plugins: {
                ...chartOptionsAvgCostPerThousandViews.plugins,
                title: {
                    ...chartOptionsAvgCostPerThousandViews.plugins.title,
                    text: "Ср. цена за тысячу показов"
                }
            }
        })
    };




    return (
        <div className="grafik">
            <div className={"grafiksContainer"}>
                <div className={"singleGrafik"}>
                    <Line options={chartOptionsViews} data={chartsDataViews} redraw key={redrawKey}/>
                </div>
                <div className={"singleGrafik"}>
                    <Bar options={chartOptionsClicks} data={chartsDataClicks} redraw key={redrawKey}/>
                </div>
                <div className={"singleGrafik"}>
                    <Line options={chartOptionsCpc} data={chartsDataCpcs} redraw key={redrawKey}/>
                </div>
                <div className={"singleGrafik"}>
                    <Line options={chartOptionsFullCost} data={chartsDataFullCost} redraw key={redrawKey}/>
                </div>
                <div className={"singleGrafik"}>
                    <Bar options={chartOptionsCtr} data={chartsDataCtr} redraw key={redrawKey}/>
                </div>
                {
                    Data.campaignType === CampaignType.DIRECT_SEARCH_AND_BANNERS
                        ?
                        <>
                            <div className={"singleGrafik"}>
                                <Line options={chartOptionsAvgPosOfViewAndClick} data={chartsDataAvgPosOfViewAndClick} redraw key={redrawKey}/>
                            </div>
                            <div className={"singleGrafik"}>
                                <Line options={chartOptionsAvgTrafficVolume} data={chartsDataAvgTrafficVolume} redraw key={redrawKey}/>
                            </div>
                        </>
                        :
                        <div className={"singleGrafik"}>
                            <Line options={chartOptionsAvgCostPerThousandViews} data={chartsDataAvgCostPerThousandViews} redraw key={redrawKey}/>
                        </div>
                }

            </div>
        </div>
    )
})


export default Grafik
