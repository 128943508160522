/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminSession } from '../models/AdminSession';
import type { Session } from '../models/Session';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * @param login 
     * @param password 
     * @returns Session Success
     * @throws ApiError
     */
    public static postApiAuthLogin(
login?: string,
password?: string,
): CancelablePromise<Session> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/login',
            query: {
                'login': login,
                'password': password,
            },
        });
    }

    /**
     * @param formData 
     * @returns Session Success
     * @throws ApiError
     */
    public static postApiAuthRefresh(
formData?: {
refreshToken?: string;
},
): CancelablePromise<Session> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/refresh',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiAuth(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/auth',
        });
    }

    /**
     * @param login 
     * @param password 
     * @returns AdminSession Success
     * @throws ApiError
     */
    public static postApiAuthAdmin(
login?: string,
password?: string,
): CancelablePromise<AdminSession> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/admin',
            query: {
                'login': login,
                'password': password,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiAuthAdmin(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/auth/admin',
        });
    }

    /**
     * @param formData 
     * @returns AdminSession Success
     * @throws ApiError
     */
    public static postApiAuthRefreshAdmin(
formData?: {
refreshToken?: string;
},
): CancelablePromise<AdminSession> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/refreshAdmin',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
