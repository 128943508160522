import {makeAutoObservable, runInAction} from "mobx";
import {
    CreatableGroup,
    GroupsService,
    GroupWithId,
    Sex
} from "../api";
import Data from "./Data";


class GroupsStore {
    groups: Array<GroupWithId> = []

    constructor() {
        makeAutoObservable(this)
    }

    async getGroupsByCampaignFromServer(campaignId: string) {
        try {
            const response = await GroupsService.getApiGroupGetGroupsByCampaign(Data.campaignId);
            runInAction(() => {
                this.groups = response;
            });

            return this.groups
        } catch (error) {
            console.error("Error: " + error);
            runInAction(() => {
                this.groups = [];
            })
            this.groups = [];
            throw false;
        }
    }

    // async addGroup(
    //     campaignId: string,
    //     group: CreatableGroup,
    //     age?: string,
    //     sex?: Sex[],
    //     ageFrom?: string,
    //     ageTo?: string,
    //     interests?: string
    // ) {
    //     try {
    //         const response = await GroupsService.postApiGroupCreateGroup(campaignId, age, sex, ageFrom, ageTo, interests, group )
    //         runInAction(() => {
    //             this.groups = [...this.groups, response as GroupWithId];
    //         })
    //         return true;
    //     } catch (error) {
    //         console.log("Error: " + error)
    //         throw false;
    //     }
    // }

    clearGroupsList() {
        this.groups = []
    }
}

export default new GroupsStore()
