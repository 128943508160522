import {AggregateStatistic, StatisticsType} from "../../api";
import {useEffect, useState} from "react";
import StatisticsStore from "../../stores/StatisticsStore";
import SingleStatElementSearch from "./SingleStatElementSearch";
import {observer} from "mobx-react";
import Data from "../../stores/Data";

const StatisticsAds = observer(() => {
    let Headers;
    const [currentType, setCurrentType] = useState<StatisticsType>();
    const [groupHasSelect, setGroupHasSelect] = useState<boolean>(false)
    const [showEmptyMessage, setShowEmptyMessage] = useState<boolean>(true)

    useEffect(() => {
        if (Data.groupId != "") {
            getStaisticsFromAdsByGroup()
        } else {
            getStaisticsFromAdsByCampaign()
        }
    }, [Data.dateFrom, Data.dateTo])

    const getStaisticsFromAdsByGroup = () => {
        let result = StatisticsStore.getStatisticsListAdsByGroup(Data.groupId, Data.dateFrom, Data.dateTo);
        result.then(res => {

        }).catch(error => {
            console.error("error: ", error)
        })
    }

    const getStaisticsFromAdsByCampaign = () => {
        let result = StatisticsStore.getStatisticsListAdsByCampaign(Data.campaignId, Data.dateFrom, Data.dateTo);
        result.then(res => {
            // console.log("res is ")
            // console.log(res)
        }).catch(error => {
            console.error("error: ", error)
        })
    }

    useEffect(() => {
        if (StatisticsStore.statisticsAdsByGroup.length > 0) {
            setCurrentType(StatisticsStore.statisticsAdsByGroup[0].type);
            setGroupHasSelect(true)
            setShowEmptyMessage(false)
        } else {
            //console.log("нет статистики по группе")
            if (StatisticsStore.statisticsAdsByCampaign.length > 0) {
                setCurrentType(StatisticsStore.statisticsAdsByCampaign[0].type)
                setGroupHasSelect(false)
                setShowEmptyMessage(false)
            } else {
                //console.log("нет статистики по кампании")
                setShowEmptyMessage(true)
            }
        }
    }, [StatisticsStore.statisticsAdsByGroup, StatisticsStore.statisticsAdsByCampaign]);

    switch (currentType){
        case StatisticsType.DIRECT_SEARCH_AND_BANNERS:
            Headers = () => {
                return <div className={"statisticsHeader searchAndBannerHeader "}>
                    <div className={"headerField"}>Объявление</div>
                    <div className={"headerField"}>Показы</div>
                    <div className={"headerField"}>Клики</div>
                    <div className={"headerField"}>CTR, %</div>
                    <div className={"headerField"}>CPC, руб.</div>
                    <div className={"headerField"}>Стоимость, руб.</div>
                    <div className={"headerField"}>Ср. ставка, руб.</div>
                    <div className={"headerField"}>Ср. позиция показа</div>
                    <div className={"headerField"}>Ср. позиция клика</div>
                    <div className={"headerField"}>Ср. объем трафика</div>
                </div>
            }
            break;
        case StatisticsType.DIRECT_MEDIA:
        case StatisticsType.VK:
            Headers = () => {
                return <div className={"statisticsHeader mediaAndVkHeader "}>
                    <div className={"headerField"}>Объявление</div>
                    <div className={"headerField"}>Показы</div>
                    <div className={"headerField"}>Клики</div>
                    <div className={"headerField"}>CTR, %</div>
                    <div className={"headerField"}>CPC, руб.</div>
                    <div className={"headerField"}>Стоимость, руб.</div>
                    <div className={"headerField"}>Цена за тысячу показов, руб.</div>
                </div>
            }
            break;
        default:
            Headers = () => null;
            break;
    }

    return (
        <div className={"StatisticsByAdsTable"}>
            <Headers/>
            {showEmptyMessage ? (
                <></>
            ) : (
                <div className={"statisticsRows"}>
                    {groupHasSelect ? (
                        StatisticsStore.statisticsAdsByGroup.map((item, index) => (
                            <SingleStatElementSearch statisticsElement={item} key={index}/>
                        ))
                    ) : (
                        StatisticsStore.statisticsAdsByCampaign.map((item, index) => (
                            <SingleStatElementSearch statisticsElement={item} key={index}/>
                        ))
                    )}
                </div>
            )}
        </div>
    )
})

export default StatisticsAds
