/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatableGroup } from '../models/CreatableGroup';
import type { EditableGroup } from '../models/EditableGroup';
import type { GroupWithAllParameters } from '../models/GroupWithAllParameters';
import type { Sex } from '../models/Sex';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GroupsService {

    /**
     * @param campaign 
     * @param age 
     * @param sex 
     * @param ageFrom 
     * @param ageTo 
     * @param interests 
     * @param requestBody 
     * @returns CreatableGroup Success
     * @throws ApiError
     */
    public static postApiGroupCreateGroup(
campaign?: string,
age?: string,
sex?: Array<Sex>,
ageFrom?: string,
ageTo?: string,
interests?: string,
requestBody?: CreatableGroup,
): CancelablePromise<CreatableGroup> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/group/createGroup',
            query: {
                'campaign': campaign,
                'age': age,
                'sex': sex,
                'ageFrom': ageFrom,
                'ageTo': ageTo,
                'interests': interests,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns CreatableGroup Success
     * @throws ApiError
     */
    public static getApiGroupGetGroupById(
id?: string,
): CancelablePromise<CreatableGroup> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/group/getGroupById',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param campaign 
     * @returns GroupWithAllParameters Success
     * @throws ApiError
     */
    public static getApiGroupGetGroupsByCampaign(
campaign?: string,
): CancelablePromise<Array<GroupWithAllParameters>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/group/getGroupsByCampaign',
            query: {
                'campaign': campaign,
            },
        });
    }

    /**
     * @param id 
     * @param age 
     * @param ageFrom 
     * @param ageTo 
     * @param sex 
     * @param interests 
     * @param requestBody 
     * @returns EditableGroup Success
     * @throws ApiError
     */
    public static patchApiGroupUpdateGroup(
id?: string,
age?: string,
ageFrom?: string,
ageTo?: string,
sex?: Sex,
interests?: string,
requestBody?: EditableGroup,
): CancelablePromise<EditableGroup> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/group/updateGroup',
            query: {
                'id': id,
                'age': age,
                'ageFrom': ageFrom,
                'ageTo': ageTo,
                'sex': sex,
                'interests': interests,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiGroupDeleteGroup(
id?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/group/deleteGroup',
            query: {
                'id': id,
            },
        });
    }

}
