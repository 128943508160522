import Data from "../../stores/Data";
import {useNavigate} from "react-router-dom";
import "../../styles/group/groupList.css"
import {usePopupWithContent} from "../PopupWithContent";
import GroupSettings from "./GroupSettings";
import {GroupWithAllParameters} from "../../api/models/GroupWithAllParameters";
import AdsListByGroup from "../ads/AdsListByGroup";

interface IGroupProps {
    group: GroupWithAllParameters
}

const SingleGroupInList = ({group}: IGroupProps) => {

    const {showMessageWithContent} = usePopupWithContent()

    const navigation = useNavigate();

    const ShowStatisticsButtonHandler = () => {
        Data.setGroupId(group.id)
        Data.setGroupName(group.name ?? "")
        Data.setGroupType(group.type)
        navigation("/statistics");
    }

    const showParametersHandler = () => {
        showMessageWithContent(`Параметры группы: ${group.name} ` ,
            <GroupSettings
                name={group.name}
                id = {group.id}
                type={group.type}
                regions={group.regions ? group.regions : ""}
                keyPhrases={group.keyPhrases ? group.keyPhrases : ""}
                age={group.age ? group.age : ""}
                ageFrom={group.ageFrom ? group.ageFrom : ""}
                ageTo={group.ageTo ? group.ageTo : ""}
                interests={group.interests ? group.interests : ""}
                sex={group.sex ? group.sex : undefined}
            />, true)
    }

    const showAdsHandler = () => {
        showMessageWithContent(`Объявления: ${group.name}`, <AdsListByGroup id={group.id}/>, true)
    }

    return (
        <div className={"SingleGroup listElement"} data-group={"group-id"}>
            <div className={"groupName"}>{group.name}</div>
            <button
                className={"showStatisticsButton hoverStyleButton"}
                onClick={ShowStatisticsButtonHandler}
            >Статистика</button>
            <div className={"singleGroupLinks"}>
                <div onClick={showParametersHandler} className={"singleGroupSettings"}>Настройки группы</div>
                <div onClick={showAdsHandler} className={"singleGroupAds"}>Посмотреть объявления</div>
            </div>
        </div>
    )
}

export default SingleGroupInList;
