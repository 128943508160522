import {useEffect, useState} from "react";
import Data from "../../stores/Data";
import {AdsType, AdsWithAllParameters, AdvertisementsService} from "../../api";
import SingleSearchAds from "./SingleSearchAds";
import SingleMediaAds from "./SingleMediaAds";
import SingleVkAds from "./SingleVkAds";
import {observer} from "mobx-react";
import AdsStore from "../../stores/AdsStore";
import "../../styles/ads/adsListByGroup.css"


const AdsListByGroup = observer(({id}:{id:string}) => {

    const [adsList, setAdsList] = useState<AdsWithAllParameters[]>();

    useEffect(() => {
        const fetchData = async () => {
            if (Data.campaignId !== "") {
                try {
                    const ads = await AdsStore.getGroupsByCampaignFromServer(id);
                    setAdsList(ads)
                } catch (error) {
                    console.error("Error fetching advertisements:", error);
                }
            }
        };
        fetchData();
    }, [])

    return(
        <div className={"adsListByGroup"} data-id={id}>
            {
              adsList?.map((item, index) => {
                  switch (item.type) {
                      case AdsType.YANDEX_SEARCH : return <SingleSearchAds ads={item} key={index}/>
                      case AdsType.YANDEX_MEDIA : return <SingleMediaAds ads={item} key={index}/>
                      case AdsType.VK : return <SingleVkAds ads={item} key={index}/>
                  }
              })
            }
        </div>
    )
})

export default AdsListByGroup
