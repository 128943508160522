/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EditableUser } from '../models/EditableUser';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * @param requestBody 
     * @returns User Success
     * @throws ApiError
     */
    public static postApiUsersCreateUser(
requestBody?: EditableUser,
): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/createUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiUsersRegister(
requestBody?: EditableUser,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/register',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns User Success
     * @throws ApiError
     */
    public static getApiUsersGetUser(
id?: string,
): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/getUser',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @returns User Success
     * @throws ApiError
     */
    public static getApiUsersGetMyUser(): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/getMyUser',
        });
    }

    /**
     * @returns User Success
     * @throws ApiError
     */
    public static getApiUsersGetUsers(): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/getUsers',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns User Success
     * @throws ApiError
     */
    public static patchApiUsersUpdateUser(
id?: string,
requestBody?: EditableUser,
): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/users/updateUser',
            query: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiUsersDeleteUser(
id?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/users/deleteUser',
            query: {
                'id': id,
            },
        });
    }

}
