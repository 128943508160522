//добавление статистики
import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {CampaignType, CreatableStatistics, StatisticsType, StatisticsWithAllParameters} from "../api";
import Data from "../stores/Data";
import StatisticsStore from "../stores/StatisticsStore";
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import {usePopup} from "./PopupMessage";
import "../styles/dataTableAddForm.css"
import {StatisticsToAddToServer} from "../CustomTypes";


const DataTableAddForm = observer(() => {

    // Регистрируем локаль для русского языка для datepicker
    registerLocale('ru', ru);
    const { showMessage } = usePopup();

    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
    const [views, setViews] = useState<number>(0);
    const [clicks, setClicks] = useState<number>(0);
    const [cpc, setCpc] = useState<number>(0);
    const [ctr, setCtr] = useState<number>(0);
    const [fullCost, setFullCost] = useState<number>(0);
    const [avgBid, setAvgBid] = useState<number>(0);

    // дополнительные поля в статистике, которые могут отличаться у разных типов кампаний
    const [avgPosOfView, setAvgPosOfView] = useState<number | undefined>( undefined);
    const [avgPosOfClick, setAvgPosOfClick] = useState<number | undefined>( undefined);
    const [avgTrafficVolume, setAvgTrafficVolume] = useState<number | undefined>( undefined);
    // доп. поля для сетей
    const [costPerThousandViews, setCostPerThousandViews ] = useState<number | undefined>( undefined);

    useEffect(()=>{
        calcCtr();
    },[views, clicks]);

    useEffect(()=>{
        calcCost();
    },[clicks, cpc]);

    const calcCtr = () => {
        // views > 0 ?
        // setCtr( Math.round(clicks / views * 100) / 100)
        //     : setCtr(0)
        const calculatedCtr = views > 0 ? Math.round((clicks / views) * 10000) / 100 : 0;
        setCtr(parseFloat(calculatedCtr.toFixed(2)));
    }

    const calcCost = () => {
        //console.log("calc cost")
        setFullCost(clicks * cpc)
    };

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    useEffect(() => {
        /*console.log("current type is " + Data.campaignType)*/
    }, [])

    const addButtonHandler = async () => {
        let typeOfStatistic: StatisticsType;
        switch (Data.campaignType) {
            case CampaignType.DIRECT_SEARCH_AND_BANNERS:
                typeOfStatistic = StatisticsType.DIRECT_SEARCH_AND_BANNERS;
                break;
            case CampaignType.DIRECT_MEDIA:
                typeOfStatistic = StatisticsType.DIRECT_MEDIA
                break;
            case CampaignType.VK:
                typeOfStatistic = StatisticsType.VK
                break;
            default:
                typeOfStatistic = StatisticsType.DIRECT_SEARCH_AND_BANNERS
        }

        // вычисляем таймзону для смещения перед преобразованием в isoString
        // const offset = selectedDate?.getTimezoneOffset();
        // const dateWithOffset = new Date(
        //     (selectedDate ? selectedDate.getTime() : 0) - (offset ? offset * 60 * 1000 : 0)
        // )
        // dateWithOffset.setHours(0,0,0,0);

        //console.log("date with offset " + dateWithOffset.toISOString());

        const creatableStatisticsElement: CreatableStatistics = {
            date: selectedDate ? selectedDate.toISOString() : "",
            views: views,
            clicks: clicks,
            cpc: cpc,
            CTR: ctr,
            fullCost: fullCost,
            avgBid: avgBid,
            type: typeOfStatistic
        }

        const elementToServer: StatisticsToAddToServer = {
            campaignId: Data.campaignId,
            costPerThousandViews: costPerThousandViews,
            avgPosOfView: avgPosOfView,
            avgPosOfClick: avgPosOfClick,
            avgTrafficVolume: avgTrafficVolume,
            creatableStatisticsElement: creatableStatisticsElement
        }


        StatisticsStore.addStatisticsToServer(elementToServer).then((response) => {
            console.log("respons from add server 2: ")
            console.log(response);
            //showMessage(response);
        }).catch((error) => {
            console.log("error from add server 2: ")
            console.log(error.body)
            console.log(error.statusText);
            showMessage(error.statusText, error.body);
        });

        setSelectedDate(null);
        setViews(0);
        setClicks(0);
        setCpc(0);
        setAvgBid(0);
        setAvgPosOfView(0);
        setAvgPosOfClick(0);
        setAvgTrafficVolume(0);
        setCostPerThousandViews(0)
    }

    const ShowAdditionalFields = () => {
        if (Data.campaignType === CampaignType.DIRECT_SEARCH_AND_BANNERS) {
            // дополнительные поля для поисковых кампаний
            return (
                <>
                    <label><span>Ср. ставка, руб.: </span> <input onChange={(event) => setAvgBid(parseFloat(event.target.value))} type={"number"} value={avgBid ? avgBid : 0}/></label>
                    <label><span>Ср. позиция показа: </span> <input onChange={(event) => setAvgPosOfView(parseFloat(event.target.value))} type={"number"} value={avgPosOfView ? avgPosOfView : 0}/></label>
                    <label><span>Ср. позиция клика: </span> <input onChange={(event) => setAvgPosOfClick(parseFloat(event.target.value))} type={"number"} value={avgPosOfClick ? avgPosOfClick : 0}/></label>
                    <label><span>Ср. объем трафика: </span> <input onChange={(event) => setAvgTrafficVolume(parseFloat(event.target.value))} type={"number"} value={avgTrafficVolume ? avgTrafficVolume : 0}/></label>
                </>
            )
        } else if (Data.campaignType === CampaignType.VK || Data.campaignType === CampaignType.DIRECT_MEDIA) {
            // дополнительные поля для банерных сетей и вк
            return (
                <>
                    <label><span>Цена за тысячу показов, руб.: </span> <input onChange={(event) => setCostPerThousandViews(parseFloat(event.target.value))} type={"number"} value={costPerThousandViews ? costPerThousandViews : 0}/></label>
                </>
            )
        } else {
            return (
                <></>
            )
        }
    }

    return (
        <div className={"DataTableDataAdd"}>
            <label>
                <span>Дата: </span>
                <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    placeholderText="Выберите дату"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="Время"
                    dateFormat="dd.MM.yyyy"
                    shouldCloseOnSelect={true}
                    locale={ru}
                />
            </label>
            <label><span>Показы: </span> <input onChange={(event) => setViews(parseFloat(event.target.value))} type={"text"} value={views ? views : 0}/></label>
            <label><span>Клики: </span> <input onChange={(event) => setClicks(parseFloat(event.target.value))} type={"text"} value={clicks ? clicks : 0}/></label>
            <label><span>CPC, руб.: </span> <input onChange={(event) => setCpc(parseFloat(event.target.value))} type={"number"} value={cpc ? cpc : 0}/></label>
            <label><span>CTR, %: </span> <input type={"text"} value={ctr ? ctr : 0} disabled={true}/></label>
            <label><span>Стоимость, руб.: </span> <input type={"number"} value={fullCost ? fullCost : 0} disabled={true}/></label>
            <ShowAdditionalFields/>
            <button className={"hoverStyleButton buttonFormAdd"} onClick={addButtonHandler}>Добавить</button>
        </div>
    );
})

export default DataTableAddForm
