import {observer} from "mobx-react";
import {useEffect, useState} from "react";
import "../styles/statisticsTotal.css"
import IconViews from "../images/icons/eye.svg"
import IconClicks from "../images/icons/clicks.svg"
import IconCtr from "../images/icons/percent.svg"
import IconCpc from "../images/icons/rub.svg"
import IconFullCost from "../images/icons/fullcost.svg"
import IconPosOfView from "../images/icons/placeView.svg"
import IconPosOfClick from "../images/icons/placeClick.svg"
import IconTrafficVolume from "../images/icons/trafficVolume.svg"
import IconBid from "../images/icons/bid.svg"
import IconCostPerThousand from "../images/icons/rub1k.svg"
import StatisticsStore from "../stores/StatisticsStore";
import Data from "../stores/Data";
import {CampaignType} from "../api";


const StatisticsTotal = observer(() => {

    const [sumOfViews, setSumOfViews] = useState<number>(0)
    const [sumOfClicks, setSumOfClicks] = useState<number>(0)
    const [avgOfCpc, setAvgOfCpc] = useState<number>(0)
    const [sumOfFullCost, setSumOfFullCost] = useState<number>(0)
    const [avgOfCtr, setAvgOfCtr] = useState<number>(0)
    const [avgOfBid, setAvgOfBid] = useState<number>(0)
    const [avgOfAvgPosOfView, setAvgOfAvgPosOfView] = useState<number>(0)
    const [avgOfAvgPosOfClick, setAvgOfAvgPosOfClick] = useState<number>(0)
    const [avgOfAvgTrafficVolume, setAvgOfAvgTrafficVolume] = useState<number>(0)
    const [avgCostPerThousandViews, setAvgCostPerThousandViews] = useState<number>(0)



    useEffect(() =>{
        const totalStat = StatisticsStore.getStatisticsTotalByCampaign(Data.campaignId, Data.dateFrom, Data.dateTo)
        totalStat.then(stat => {
            setSumOfViews(stat ? parseFloat(stat.views.toFixed(2)) : 0)
            setSumOfClicks(stat ? parseFloat(stat.clicks.toFixed(2)) : 0)
            setSumOfFullCost(stat ? parseFloat(stat.fullCost.toFixed(2)) : 0)
            setAvgOfCpc(stat ? parseFloat(stat.cpc.toFixed(2)) : 0)
            setAvgOfCtr(stat ? parseFloat(stat.CTR.toFixed(2)) : 0)
            setAvgOfBid(stat ? parseFloat(stat.avgBid.toFixed(2)) : 0)
            setAvgOfAvgPosOfView(stat && stat.avgPosOfView !== null && stat.avgPosOfView !== undefined ? parseFloat(stat.avgPosOfView.toFixed(2)) : 0)
            setAvgOfAvgPosOfClick(stat && stat.avgPosOfClick !== null && stat.avgPosOfClick !== undefined ? parseFloat(stat.avgPosOfClick.toFixed(2)) : 0)
            setAvgOfAvgTrafficVolume(stat && stat.avgTrafficVolume !== null && stat.avgTrafficVolume !== undefined ? parseFloat(stat.avgTrafficVolume.toFixed(2)) : 0)
            setAvgCostPerThousandViews(stat && stat.costPerThousandViews !== null && stat.costPerThousandViews !== undefined ? parseFloat(stat.costPerThousandViews.toFixed(2)) : 0)
        })
    }, [Data.dateFrom, Data.dateTo, Data.campaignId])

    return (
            <>
                <h2>Краткая сводка</h2>
                <div className={"StatisticsTotal"} >
                    <div className={"StatisticsContainer"}>
                        <div className={"singleStatisticsTotal"}>
                            <div className={"singleStatisticsTotalLeft"}>
                                <div className={"totalName"}>Показы</div>
                                <div className={"totalvalue"}>{sumOfViews}</div>
                            </div>
                            <img className={"totalIcon"} src={IconViews}/>
                        </div>
                        <div className={"singleStatisticsTotal"}>
                            <div className={"singleStatisticsTotalLeft"}>
                                <div className={"totalName"}>Клики</div>
                                <div className={"totalvalue"}>{sumOfClicks}</div>
                            </div>
                            <img className={"totalIcon"} src={IconClicks}/>
                        </div>
                        <div className={"singleStatisticsTotal"}>
                            <div className={"singleStatisticsTotalLeft"}>
                                <div className={"totalName"}>CTR</div>
                                <div className={"totalvalue"}>{avgOfCtr} %</div>
                            </div>
                            <img className={"totalIcon"} src={IconCtr}/>
                        </div>
                        <div className={"singleStatisticsTotal"}>
                            <div className={"singleStatisticsTotalLeft"}>
                                <div className={"totalName"}>Ср. цена клика</div>
                                <div className={"totalvalue"}>{avgOfCpc} ₽</div>
                            </div>
                            <img className={"totalIcon"} src={IconCpc}/>
                        </div>
                        <div className={"singleStatisticsTotal"}>
                            <div className={"singleStatisticsTotalLeft"}>
                                <div className={"totalName"}>Стоимость</div>
                                <div className={"totalvalue"}>{sumOfFullCost} ₽</div>
                            </div>
                            <img className={"totalIcon"} src={IconFullCost}/>
                        </div>



                        {(Data.campaignType === CampaignType.DIRECT_SEARCH_AND_BANNERS) ?
                            <>
                                {/*Search and banners fields*/}
                                <div className={"singleStatisticsTotal"}>
                                    <div className={"singleStatisticsTotalLeft"}>
                                        <div className={"totalName"}>Ср. позиция показа</div>
                                        <div className={"totalvalue"}>{avgOfAvgPosOfView}</div>
                                    </div>
                                    <img className={"totalIcon"} src={IconPosOfView}/>
                                </div>
                                <div className={"singleStatisticsTotal"}>
                                    <div className={"singleStatisticsTotalLeft"}>
                                        <div className={"totalName"}>Ср. позиция клика</div>
                                        <div className={"totalvalue"}>{avgOfAvgPosOfClick}</div>
                                    </div>
                                    <img className={"totalIcon"} src={IconPosOfClick}/>
                                </div>
                                <div className={"singleStatisticsTotal"}>
                                    <div className={"singleStatisticsTotalLeft"}>
                                        <div className={"totalName"}>Ср. объем трафика</div>
                                        <div className={"totalvalue"}>{avgOfAvgTrafficVolume} %</div>
                                    </div>
                                    <img className={"totalIcon"} src={IconTrafficVolume}/>
                                </div>
                                <div className={"singleStatisticsTotal"}>
                                    <div className={"singleStatisticsTotalLeft"}>
                                        <div className={"totalName"}>Ср. ставка за клик</div>
                                        <div className={"totalvalue"}>{avgOfBid} ₽</div>
                                    </div>
                                    <img className={"totalIcon"} src={IconBid}/>
                                </div>
                            </>:
                            <>
                                {/*Media and Vk fields*/}
                                <div className={"singleStatisticsTotal"}>
                                    <div className={"singleStatisticsTotalLeft"}>
                                        <div className={"totalName"}>Цена за 1 тыс. показов</div>
                                        <div className={"totalvalue"}>{avgCostPerThousandViews} ₽</div>
                                    </div>
                                    <img className={"totalIcon"} src={IconCostPerThousand}/>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </>

    )

})

export default StatisticsTotal
