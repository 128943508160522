import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {AccountType, CampaignType, CreatableCampaign} from "../api";
import "../styles/addAccountForm.css"
import Select, {ActionMeta} from "react-select";
import {usePopupWithContent} from "./PopupWithContent";
import {usePopup} from "./PopupMessage";
import CampaignsStore from "../stores/CampaignsStore";
import Data from "../stores/Data";
import "../styles/addCapaignForm.css"

const AddCampaignForm = observer(() => {
    const options = [
        {
            value: CampaignType.DIRECT_SEARCH_AND_BANNERS,
            label: "Поисковая или баннерная с оплатой за клики"
        },
        {
            value: CampaignType.DIRECT_MEDIA,
            label: "Баннерная с оплатой за показы"
        }
    ]

     const { showMessage } = usePopup();

    const [campaignType, setCampaignType] = useState<CampaignType | null>();
    const [campaignName, setCampaignName] = useState<string>("")


    const typeSelectChange = (selectedOption: { value: CampaignType; label: string } | null, actionMeta: ActionMeta<{ value: CampaignType; label: string }>) => {
        if (selectedOption) {
            setCampaignType(selectedOption.value);
        } else {
            // Обработка, если выбрано пустое значение (null)
            setCampaignType(null);
        }
    };

     const {closeMessage} = usePopupWithContent()

    const addCampaignBtnHandler = () => {

        if (
            campaignName?.length === 0 ||
            campaignName?.length === 0 ||
            campaignType === null ||
            campaignType === undefined
        ) {
            console.log("Заполните все поля")

            Data.accountType === AccountType.VK ?
            showMessage("Заполните форму","Перед созданием кампании необходимо заполнить обязательные поля: название и список городов") :
                showMessage("Заполните форму","Перед созданием кампании необходимо заполнить обязательные поля: название, список городов и выбрать необходимый тип кампании")
        } else {
            const creatableCampaign: CreatableCampaign = {
                name: campaignName || '',
                type: campaignType || CampaignType.VK,
            }
            console.log("сейчас отправим в стор вот это: ")
            console.log(creatableCampaign)
            CampaignsStore.addCampaign(Data.accountId, creatableCampaign)
            setCampaignName("")
            setCampaignType(undefined)
            closeMessage()
        }
    }

    useEffect(() => {
        Data.accountType === AccountType.VK ? setCampaignType(CampaignType.VK) : setCampaignType(CampaignType.DIRECT_SEARCH_AND_BANNERS)
    }, [Data.accountType])

    return (
        <div className={"AddCampaignForm"}>
            <label>
                <span>Название кампании: </span>
                <input onChange={(event) => setCampaignName(event.target.value)} type={"text"} value={campaignName ? campaignName : ""}/>
            </label>
            {
                // выбираем тип кампании, если аккаунт - яндекс директ. иначе будет только один тип кампании - vk
                Data.accountType === AccountType.VK ? <></> :
                    <label>
                        <span>Тип кампании: </span>
                        <Select
                            className="campaignTypeSelect"
                            options={options}
                            isSearchable={false}
                            name="campaignType"
                            placeholder="Тип кампании"
                            onChange = {typeSelectChange}
                            value={campaignType ? options.find(option => option.value === campaignType) : null}
                        />
                    </label>
            }

            <button
                onClick={addCampaignBtnHandler}
            >Добавить</button>
        </div>
    )
})

export default AddCampaignForm
