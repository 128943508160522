/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Account } from '../models/Account';
import type { CreatableAccount } from '../models/CreatableAccount';
import type { EditableAccount } from '../models/EditableAccount';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccountsService {

    /**
     * @param user 
     * @param requestBody 
     * @returns Account Success
     * @throws ApiError
     */
    public static postApiAccountCreateAccount(
user?: string,
requestBody?: CreatableAccount,
): CancelablePromise<Account> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/account/createAccount',
            query: {
                'user': user,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns Account Success
     * @throws ApiError
     */
    public static getApiAccountGetAccount(
id?: string,
): CancelablePromise<Account> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/account/getAccount',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @returns Account Success
     * @throws ApiError
     */
    public static getApiAccountGetAllAccounts(): CancelablePromise<Array<Account>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/account/getAllAccounts',
        });
    }

    /**
     * @param userId 
     * @returns Account Success
     * @throws ApiError
     */
    public static getApiAccountGetAllAccountsByUser(
userId?: string,
): CancelablePromise<Array<Account>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/account/getAllAccountsByUser',
            query: {
                'userId': userId,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns Account Success
     * @throws ApiError
     */
    public static patchApiAccountUpdateAccount(
id?: string,
requestBody?: EditableAccount,
): CancelablePromise<Account> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/account/updateAccount',
            query: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiAccountDeleteAccount(
id?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/account/deleteAccount',
            query: {
                'id': id,
            },
        });
    }

}
