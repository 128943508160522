import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {usePopupWithContent} from "../PopupWithContent";
import Data from "../../stores/Data";
import CampaignsStore from "../../stores/CampaignsStore";
import SingleGroupInList from "./SingleGroupInList";
import {UserType} from "../../api";
import AddCampaignForm from "../AddCampaignForm";
import GroupsStore from "../../stores/GroupsStore";

const GroupsList = observer(() => {

    const [heading, setHeading] = useState<string>("")
    const [emptyGroupsList, setEmptyGroupsList] = useState<boolean>(false);
    const {showMessageWithContent} = usePopupWithContent()

    useEffect(() => {
        if (Data.campaignId !== "") {
            GroupsStore.getGroupsByCampaignFromServer(Data.campaignId)
            setHeading("Выберите группу")
        } else {
            setHeading("Сперва выберите кампанию")
        }

    }, [Data.campaignId])

    useEffect(() => {
        if (Data.accountId && CampaignsStore.campaigns.length === 0) {
            setEmptyGroupsList(true)
            setHeading("Нет созданных групп")
        } else {
            setEmptyGroupsList(false)
            setHeading("Выберите группу")
        }
    }, [Data.accountId, GroupsStore.groups])

    useEffect(() => {
        GroupsStore.clearGroupsList()
    }, [Data.userId])

    const buttonAddGroupHandler = () => {
        //showMessageWithContent("Создать новую группу", <AddCampaignForm/>)
    }


    return (
        <div className={"groupsList singleColumnOnMainPage"}>
            <div className={"heading"}>{heading}</div>
            <div className={"groupsListContainer"}>
                {
                    emptyGroupsList ?
                        <div className={"emptyListMessage"}>Для этой рекламной кампании нет созданных групп объявлений.</div>
                        :
                        GroupsStore.groups.map(group => {
                            return <SingleGroupInList group={group} key={group.id}/>
                        })
                }
            </div>
            {
                Data.userType === UserType.ADMIN && Data.accountId !== "" ?
                    <button className={"addGroupBtn hoverStyleButton"} onClick={buttonAddGroupHandler}>Создать новую группу</button> : <></>
            }
        </div>
    )
})

export default GroupsList;
