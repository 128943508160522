import {FormEvent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Header from "../components/header/Header";
import {AuthService} from "../api";
import "../styles/LoginPage.css"
import {Helmet} from "react-helmet-async";


const LoginAdminPage = () => {
    const [login, setLogin] = useState<string>("")
    const [password, setPasswords] = useState<string>("")
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>("")

    const navigation = useNavigate()

    useEffect(() => {
        if (localStorage.getItem("accessToken") != null) {
            navigation('/')
        }
    },[])



    const handleLoginSubmit = (event: FormEvent) => {
        event.preventDefault()
        setIsLoading(true)
        if (login.length == 0 || password.length == 0) {
            setIsLoading(false)
            alert("check fields")
        } else {
            try {
                //const response = AuthService.postApiAuthLogin(login, password)
                const response = AuthService.postApiAuthAdmin(login, password)
                response.then(resp => {
                    console.log("resp")
                    console.log(resp)
                    if (resp.accessToken)
                        localStorage.setItem("accessToken", resp.accessToken)
                    if (resp.refreshToken)
                        localStorage.setItem("refreshToken", resp.refreshToken)


                    setIsLoading(false)
                    navigation('/')
                }).catch(err => {
                    console.log("auth error:")
                    console.log(err)
                    console.log(err.body)
                    setError(err.body)
                    setIsLoading(false)
                })
            } catch (error) {
                console.error("Error: " + error);
                setIsLoading(false)
                throw false;
            }
        }
    }


    return (
        <>
            <Helmet>
                <title>Админка | UnyMark</title>
            </Helmet>
            <Header/>
            <div className={"LoginPage pageWrapper"}>
                <div className="container">
                    <div className={"auth"}>
                        <h1>Вход в систему V2</h1>
                        <form onSubmit={handleLoginSubmit} className={"loginForm"}>
                            <label>
                                E-mail:
                                <input type={"text"} value={login} onChange={event => setLogin(event.target.value)}/>
                            </label>
                            <label>
                                Пароль:
                                <input type={"password"} value={password} onChange={event => setPasswords(event.target.value)}/>
                            </label>
                            <button type={"submit"} className={"hoverStyleButton"}>Войти</button>
                            <div className={"errorField"}>{error}</div>
                        </form>
                        <div className={"loginLinksContainer"}>
                            <a href={"#"}
                               onClick={() => {
                                   navigation('/login')
                               }}
                            >Обычный вход</a>
                            {/*<a href={"/restorepassword"}>Восстановить пароль</a>*/}
                        </div>
                        {isLoading ? <div className={"loader"}></div> : <></>}
                    </div>
                </div>
            </div>
        </>
    )

}

export default LoginAdminPage
