import {CampaignType, UserType} from "../api";
import Data from "../stores/Data";
import {useState} from "react";

interface IHeaderProps {
    type: CampaignType | undefined
}


const StatisticsHeader = ({type}: IHeaderProps) => {
    if (type ===  CampaignType.DIRECT_SEARCH_AND_BANNERS) {
        return (
            <DirectSearchAndBannerHeader/>
        )
    } else if (type === CampaignType.DIRECT_MEDIA || type === CampaignType.VK) {
        return (
            <DirectMediaHeader/>
        )
    } else {
        return <>Неизвестный тип заголовка статистики</>
    }
}

const DirectSearchAndBannerHeader = () => {
    return(
        <div className={"statisticsHeader searchAndBannerHeader "}>
            <div className={"headerField"}>Дата</div>
            <div className={"headerField"}>Показы</div>
            <div className={"headerField"}>Клики</div>
            <div className={"headerField"}>CTR, %</div>
            <div className={"headerField"}>CPC, руб.</div>
            <div className={"headerField"}>Стоимость, руб.</div>
            <div className={"headerField"}>Ср. ставка, руб.</div>
            <div className={"headerField"}>Ср. позиция показа</div>
            <div className={"headerField"}>Ср. позиция клика</div>
            <div className={"headerField"}>Ср. объем трафика</div>
            {
                Data.userType === UserType.ADMIN ?<div className={"headerField"}>Удалить</div> : <></>
            }
        </div>
    )
}

const DirectMediaHeader = () => {
    return(
        <div className={"statisticsHeader mediaAndVkHeader"}>
            <div className={"headerField"}>Дата</div>
            <div className={"headerField"}>Показы</div>
            <div className={"headerField"}>Клики</div>
            <div className={"headerField"}>CTR, %</div>
            <div className={"headerField"}>CPC, руб.</div>
            <div className={"headerField"}>Стоимость, руб.</div>
            <div className={"headerField"}>Цена за тысячу показов, руб.</div>
            {
                Data.userType === UserType.ADMIN ?<div className={"headerField"}>Удалить</div> : <></>
            }
        </div>
    )
}

export default  StatisticsHeader
