import {makeAutoObservable, runInAction} from "mobx";
import {Account, AccountsService, CreatableAccount} from "../api";

class AccountsStore {
    accounts: Array<Account> = []

    constructor() {
        makeAutoObservable(this)
    }

    async getAllAccountsByUser(userId: string) {
        await AccountsService.getApiAccountGetAllAccountsByUser(userId).then(response => {
            runInAction(() => {
                this.accounts = response;
            });
            return this.accounts
        }).catch(err => {
            console.error("Error: " + err);
            runInAction(() => {
                this.accounts = [];
            })
            //throw false;
            return this.accounts
        });



    }

    async addAccount(userId: string, account: CreatableAccount) {
        try {
            const response = await AccountsService.postApiAccountCreateAccount(userId, account);
            runInAction(() => {
                this.accounts = [...this.accounts, response as Account]
            })
            return true;
        } catch (error) {
            console.log("Error: " + error)
            throw false;
        }
    }
}

export default new AccountsStore()
